button,
.btn {
    font-weight: $font-weight-semibold;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: $border-radius-small;
    border: 1px solid transparent;
    color: $white;
    background: rgba(242, 242, 242, .4);
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    &.btn-small {
        height: 4.1rem;
    }

    &.btn-huge {
        height: 6rem;
    }

    &.btn-icon {
        padding: 1rem 2.3rem;
    }

    &.btn-outline {
        border: 1px solid var(--font-color-inverted);
        background: transparent;
    }

    @each $name, $fontColor, $color, $bgHoverStart, $bgHoverEnd, $hover-direction in $btn-variations {
        &.btn-#{$name} {
            border-color: #{$color};
            @if $name == 'transparent' {
                background: transparent;
            } @else {
                background: linear-gradient(#{$hover-direction}, #{$color} 0%, #{$color} 100%);
            }

            color: #{$fontColor};

            &:focus,
            &:hover {
                background: linear-gradient(#{$hover-direction}, #{$bgHoverStart} 0%, #{$bgHoverEnd} 100%);
                border-color: transparent;
            }

            &.btn-error {
                color: $white;
                background: linear-gradient(45deg, $error 0%, $error 100%);
                border-color: $error;

                &:hover {
                    color: $white;
                    background: linear-gradient(45deg, $error 0%, $input-error 100%);
                    border-color: $error;
                }
            }
        }

        &.btn-outline-#{$name} {
            border-color: #{$color};
            background: transparent;
            color: #{$color};

            &:focus,
            &:hover {
                color: #{$bgHoverEnd};
                border-color: #{$bgHoverEnd};
            }

            &.btn-error {
                color: $error;
                border-color: $error;

                &:hover {
                    color: $input-error;
                    border-color: $input-error;
                }
            }
        }
    }


    &:disabled,
    &.btn-disabled,
    fieldset:disabled & {
        background: rgba(255, 253, 247, 0.3);
        border-color: rgba(255, 253, 247, 0.3);
        cursor: not-allowed;
        color: rgba(0, 0, 30, 0.4);

        &:hover {
            background: rgba(255, 253, 247, 0.3);
            border-color: rgba(255, 253, 247, 0.3);
            cursor: not-allowed;
            color: rgba(0, 0, 30, 0.4);
        }
    }

    img {
        padding-inline: 1rem;
    }

    &.btn-link,
    &.btn-link-primary {
        padding: 0;
        // margin: 0;
        background: transparent;
        text-decoration: underline;
        height: auto;
    }

    &.btn-link-primary {
        color: var(--default-color);
    }

    &.btn-full {
        width: 100%;
        // margin-inline: 0;
    }

    &.btn-large {
        padding-inline: 6.66rem;
    }
}

.copy-code {
    .default {
        visibility: visible;
        opacity: 1;
        width: auto;
        @extend %transition-slow;
    }
    .copied {
        visibility: hidden;
        opacity: 0;
        width: 0;
        @extend %transition-slow;
    }

    &.clicked {
        .default {
            visibility: hidden;
            opacity: 0;
            width: 0;
            @extend %transition-slow;
        }
        .copied {
            visibility: visible;
            opacity: 1;
            width: auto;
            @extend %transition-slow;
        }
    }
}


#showMenuUniverses {
    svg {
        margin-inline: 1rem;
    }
}