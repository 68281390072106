.who-we-are--description {
    font-size: 2.4rem;
    em {
        font-style: normal;
        font-weight: $font-weight-bold;
        font-size: 3rem;
        color: var(--default-color);
        margin-block: 1.6rem;
        display: block;
    }
}

.who-we-are--header {
    h2 {
        font-size: 4rem;
    }

    strong {
        display: block;
        font-weight: $font-weight-bold;
        font-size: 2.4rem;
    }

    small {
        opacity: 75%;
    }

    iframe {
        width: 358px;
        height: 200px;
    }
}

.who-we-are--main {
    background-color: #05073F;
    h2 {
        margin-bottom: 0;
    }
}

.who-we-are--vision-mission {
    background-image: url('/images/who-we-are/main.webp');
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 200px;
}

.who-we-are--vision-mission--card {
    border: 1px solid #FCB83E;
    backdrop-filter: blur(12px);

    p {
        font-size: 1.8rem;
    }
}

.who-we-are--difference-card {
    .square {
        aspect-ratio: initial;
        height: auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

#our-values {
    items {
        align-items: stretch;
    }

    .item {
        width: 80%;
        backdrop-filter: blur(50px);
        justify-content: flex-start;
    }
}

.who-we-are--contact {
    border: 1px solid #FCB83E;
    backdrop-filter: blur(50px);
}

.who-we-are--partners {
    .item {
        width: 175px;
        height: 113px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .square {
            padding: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}