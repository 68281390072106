/* ----------------------------- */
/* ==Print (quick print reset)   */
/* ----------------------------- */

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  body {
    width: auto;
    margin: auto;
    font-family: serif;
    font-size: 12pt;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  label,
  ul,
  ol {
    color: #000;
    margin: auto;
  }

  .print {
    display: block;
  }

  .no-print {
    display: none;
  }

  /* no orphans, no widows */
  p,
  blockquote {
    orphans: 3;
    widows: 3;
  }

  /* no breaks inside these elements */
  blockquote,
  ul,
  ol {
    page-break-inside: avoid;
  }

  /* page break before main headers
  h1,
  .h1-like {
    page-break-before: always;
  }
  */

  /* no breaks after these elements */
  h1,
  h2,
  h3,
  caption {
    page-break-after: avoid;
  }

  a {
    color: #000;
  }

  /* displaying URLs
  a[href]::after {
    content: " (" attr(href) ")";
  }
  */

  a[href^="javascript:"]::after,
  a[href^="#"]::after {
    content: "";
  }
}
