.modal {
    position: fixed;
    padding-inline: 2.4rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 10;

    background: rgba(#03023B, 0.7);
    @if $theme == 'lebeaujeu' {
        background: rgba(#000000, 0.7);
    }

    &.show {
        visibility: visible;
        overflow-y: auto;
    }

}

.modal-dialog {
    margin-top: 2.4rem;
    position: absolute;
    z-index: 15;
    top: 0;
    max-width: 90vw;
    border-radius: $border-radius-default;
    // background: linear-gradient(70.2deg, rgba(255, 255, 255, 0.15) 0%, rgba(247, 247, 247, 0.15) 100%), rgba(#03023B, 0.7);
    background: linear-gradient(70.2deg, rgba(255, 255, 255, 0.15) 0%, rgba(247, 247, 247, 0.15) 100%), rgba(#03023B, 1);
    @if $theme == 'lebeaujeu' {
        background: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(50px);
    }
    & .modal-header, .modal-body, .modal-footer {
        width: 100%;
    }
}

.modal-header {
    font-size: 3rem;
    font-weight: $font-weight-bold;
    min-height: 6.6rem;
    line-height: 3.3rem;

    button {
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        right: 3.2rem;
        top: 2.4rem;
        padding: 0;
        margin: 0;
        background: transparent;

        img {
            padding: 0;
        }
    }
}

.modal-footer {
    button {
        width: 100%;
    }
}