.rotated-card-image-container {
    .bubble {
        font-size: 1.6rem;
        font-weight: $font-weight-bold;

        &.visible {
            &.recipientName {
                top: 4rem;
            }
            &.right {
                right: -14rem;
            }
        }
    }

    .universes {
        width: 4.2rem;
        height: 4.2rem;
        border-radius: 4.2rem;

        img {
            width: 2.6rem;
        }

        &.firstUniverse {
            left: -8rem;

            &::before {
                top: -0.4rem;
                left: -1.9rem;
            }
            &::after {
                top: 2.4rem;
                left: -1.5rem;
            }
        }
        &.secondUniverse {
            right: -6.1rem;
        }

        &.visible {
            &.firstUniverse {
                top: -4rem;
            }

            &.secondUniverse {
                top: 8rem;
            }
        }
    }

    .message {
        width: 4.2rem;
        height: 4.2rem;
        border-radius: 4.2rem;

        img {
            width: 2.6rem;
        }

        &.visible {
            visibility: visible;
            opacity: 1;
            top: -2rem;
            right: -14rem;
        }
    }

    .gif {
        width: 9rem;
        height: auto;
        top: 6rem;

        img {
            width: 9rem;
            max-height: 8rem;
        }

        &::before {
            top: -1.2rem;
            left: -0.4rem;
        }
        &::after {
            bottom: -0.4rem;
            right: -1.8rem;
        }

        &.visible {
            visibility: visible;
            opacity: 1;
            left: -21rem;
        }
    }

    .video {
        right: unset;
        top: unset;
        left: -40rem;
        bottom: 0;

        video {
            width: 7.5rem;
        }

        &.visible {
            visibility: visible;
            opacity: 1;
            bottom: 8rem;
            left: -21rem;
            top: unset;
        }
    }
}

.cards-presentation {
    width: 70vw;
    max-width: 96rem;
    margin: 0 auto;

    .rotated-card-image-container {
        max-width: 41rem;
    }
}
.cards-presentation-assets {
    max-width: 56rem;
}

.cards-presentation-form {
    border-radius: $border-radius-default;

    &.step-6 {
        .button-type-container {
            order: 1;
        }
        .cards-container {
            order: 2;
        }

        .cards-container {
            label {
                width: auto;

                svg {
                    width: 17.6rem;
                }
            }

            input {
                display: none;
                visibility: hidden;
            }
        }
    }
}

.btn.cards-presentation-btn-message-type {
    align-items: flex-start;
}

.cards-presentation-message-type-message-extra {
    .gifChoice {
        width: 19rem;
    }
    label {
        &:has(input:checked) {
            &::after {
                bottom: 0;
                right: 0;
            }
        }
    }
}

.card-preview-form {
    max-width: 64rem;
}