.b2b--hero {
    min-height: 650px;
    background-image: url('/images/b2b/partners/hero.webp');

    h1,
    p {
        max-width: 1400px;
    }
}

.b2b--partners--sliders {
    ul {
        overflow: hidden;
    }

    .partner {
        padding: 36px;
        width: 205px;
        height: 132px;
    }
}

.b2b--partners--sliders {
    .b2b--partners--slider-universe {
        background-size: contain;

        p {
            font-size: 3.2rem;
        }
    }
}