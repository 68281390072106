body.body-unboxing {
    padding: 0;
    margin: 0;
    min-height: auto;
    overflow: hidden;

    main {
        // height: calc(100vh - 5.7rem);
        // height: 90vh;
        position: absolute;
        top: 20px;
        right: 0;
        bottom: 20px;
        left: 0;
        margin: 0;
        // margin-bottom: 5.7rem;
    }
}

.unboxing-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 60rem;

    .preview {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .card {
        .rotated-card-image-container {
            width: unset;
            margin: unset;
            margin-inline: 8rem;

            img,
            svg {
                width: 100%;
            }

            .rotated-card-image-price {
                font-size: 4rem;
                bottom: 3.6rem;
            }
        }
    }


}

.unboxing {
    .stepper {
        display: grid;
        grid-template-columns: repeat(var(--step), 1fr);
        gap: 0.2rem;
        height: 0.3rem;
        width: 100%;
        margin: 0;
        padding: 0 1rem;
        margin-top: 3.4rem;
        position: relative;
        z-index: 5;
        opacity: 0;

        &.visible {
            opacity: 1;
        }

        li {
            position: relative;
            display: inline-block;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 0.3rem;
                background-color: white;
                border-radius: 0.3rem;
                opacity: 0.5;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 0;
                height: 0.3rem;
                background-color: white;
                border-top-left-radius: 0.3rem;
                border-bottom-left-radius: 0.3rem;
            }

            &.active {
                &:after {
                    width: 100%;
                    transition: all var(--timeout) ease-in-out;
                }
            }
            &.done {
                &:after {
                    width: 100%;
                }
            }
        }
    }
    .step {
        position: absolute;
        padding-top: 5.7rem;
        // top: 50%;
        // left: 50%;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        transition: all 750ms ease-in-out;

        background-image: url('/images/backgrounds/main/webp/59a3d8.webp');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        &.active {
            visibility: visible;
            opacity: 1;
            transition: all 1250ms ease-in-out;
        }

        h1 {
            font-size: 2.4rem;
        }
    }

    .animation {
        visibility: visible;
        @extend %transition-slow;
        opacity: 1;

        &.hide {
            visibility: hidden;
            opacity: 0;
            @extend %transition-slow;
        }
    }

    .img-radius {
        border-radius: $border-radius-default;
        margin-inline: 3.2rem;
    }

    .message {
        .sender {
            opacity: 0.6;
        }
    }

    .universe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;

        &.visible {
            visibility: visible;
            opacity: 1;
            transition: all 3500ms ease-in-out;
        }

        h1 {
            background-position: center top;
            background-repeat: no-repeat;
            padding-top: 4.7rem;
        }

        strong {
            font-weight: $font-weight-regular;
            color: var(--color);
        }

    }

    .bento {
        display: grid;
        grid-template-columns: calc(19.83% - 0.8rem) calc(6.75% - 0.8rem) calc(17.04% - 0.8rem) calc(9.63% - 0.8rem) calc(7.51% - 0.8rem) calc(5.57% - 0.8rem) 33.67%;
        grid-template-rows: calc(8.48% - 0.8rem) calc(5.25% - 0.8rem) calc(11.44% - 0.8rem) calc(9.33% - 0.8rem) calc(16.04% - 0.8rem) calc(3.28% - 0.8rem) calc(7.88% - 0.8rem) calc(13.27% - 0.8rem) calc(9.46% - 0.8rem) calc(3.41% - 0.8rem) 12.16%;
        height: 100%;
        width: 100%;
        padding: 1rem;
        position: relative;
        // z-index: 2;

        div {
            border-radius: $border-radius-default;
            border-width: 0.1rem;
            border-style: solid;
            border-color: var(--border-color);

            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .to-left,
        .to-right {
            &.hide {
                opacity: 0;
                transition: all 750ms ease-in-out;
            }
        }
        .to-left {
            &.hide {
                transform: translateX(-30vw);
            }
        }
        .to-right {
            &.hide {
                transform: translateX(30vw);
            }
        }

        .logo {
            background: rgba(0, 0, 30, 0.4);
            backdrop-filter: blur(50px);
            padding: 0.6rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .A1 {
            grid-column: 1 / 3;
        }
        .C1 {
            grid-column: 3/ 5;
        }
        .E1 {
            grid-column: 5 / 8;
            grid-row: 1 / 3;
        }

        .A2 {
            grid-column: 1 / 5;
            grid-row: 2 / 4;
        }

        .E3 {
            grid-column: 5 / 8;
            grid-row: 3 / 4;
        }

        .A4 {
            grid-column: 1 / 2;
            grid-row: 4 / 5;
        }
        .B4 {
            grid-column: 2 / 6;
            grid-row: 4 / 5;
        }
        .F4 {
            grid-column: 6 / 8;
            grid-row: 4 / 5;
        }

        .A5 {
            grid-column: 1 / 4;
            grid-row: 5 / 6;
        }
        .D5 {
            grid-column: 4 / 8;
            grid-row: 5 / 7;
        }

        .A6 {
            grid-column: 1 / 4;
            grid-row: 6 / 11;
        }

        .D7 {
            grid-column: 4 / 7;
            grid-row: 7 / 9;
        }

        .G7 {
            grid-column: 7 / 8;
            grid-row: 7 / 8;
        }

        .G8 {
            grid-column: 7 / 8;
            grid-row: 8 / 9;
        }

        .D9 {
            grid-column: 4 / 7;
            grid-row: 9 / 10;
        }
        .G9 {
            grid-column: 7 / 8;
            grid-row: 9 / 12;
        }

        .D10 {
            grid-column: 4 / 7;
            grid-row: 10 / 12;
        }

        .A11 {
            grid-column: 1 / 4;
            grid-row: 11 / 12;
        }
    }

    .card,
    .card-holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .card {
        margin: auto;
        margin-top: -0.6rem;

        &.touched {
            transition: all 500ms ease-in-out;
            transform: translateX(-0.5rem) translateY(-8rem) scale(1.2);
        }
    }

    .card-holder {
        z-index: 3;
        &.touched {
            transition: all 1500ms ease-in-out;
            opacity: 0;
            transform: rotate(3deg) translateY(100vh);
        }
    }

    .rotated-card-image-container {
        margin: auto;

        img,
        svg {
            width: 19rem;
        }
    }

    .end {
        font-size: 2.6rem;
        font-weight: $font-weight-semibold;
        padding-bottom: 13rem;
        visibility: hidden;
        opacity: 0;

        &.visible {
            opacity: 1;
            visibility: visible;
            transition: all 500ms ease-in-out;
        }
    }

    video {
        width: 100%;
        height: auto;
    }

    .btn {
        transition: all 500ms ease-in-out;

        &.btn-call {
            position: relative;
            height: 6rem;
            border-radius: 6rem;
            border: 0.1rem solid $white;
            padding-left: 6rem;

            .phone {
                position: absolute;
                left: 0.5rem;
                top: 0.5rem;
                width: 5rem;
                height: 5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                background: linear-gradient(70.2deg, #FFFFFF 0%, #F7F7F7 100%);
                box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.25);
                border-radius: 5rem;

                img {
                    width: 2rem;
                    padding: 0;
                    margin: 0;
                }
            }

            &.active {
                .phone {
                    transition: 750ms ease-in-out;
                    left: calc(100% - 5.5rem);
                }
                span {
                    opacity: 0;
                }
            }
        }

        &.hide {
            visibility: hidden;
            opacity: 0;
            transition: all 500ms ease-in-out;
            transform: translateY(-12rem);
        }

        &.done {
            visibility: hidden;
            opacity: 0;
            transition: all 500ms ease-in-out;
            transform: translateY(30vw);
        }
    }

    .bottom {
        z-index: 4;
        position: absolute;
        bottom: 1rem;
        width: 100%;
        visibility: hidden;
        opacity: 1;
        transition: all 500ms ease-in-out;

        &.visible {
            visibility: visible;
            opacity: 1;
        }
    }

    h1 {
        font-size: 3rem;
        line-height: normal;
    }

    h2 {
        font-size: 2rem;
        font-weight: $font-weight-light;
        line-height: normal;
        margin-top: 4.7rem;
        opacity: 0;
        visibility: hidden;

        transition: all 350ms ease-in-out;

        &.visible {
            opacity: 1;
            visibility: visible;
        }

        strong {
            font-size: 3rem;
            font-weight: $font-weight-bold;
        }
    }
}