.alert {
    padding: 1rem;
    font-weight: $font-weight-medium;
    margin: 2rem auto;
    width: 100%;
}

@each $name, $border, $bgStart, $bgEnd, $fontColor in $alert-variations {
    .alert-#{$name} {
        border: .2rem solid #{$border};
        background: linear-gradient(45deg, #{$bgStart} 0%, #{$bgEnd} 100%);
        border-radius: $border-radius-default;
        color: #{$fontColor};
    }
}