.slider-container {
    width: 100%;

    div.navigation {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        button {
            width: 4rem;
            height: 4rem;
            border: .1rem solid var(--default-color);
            background: transparent;
            border-radius: 4rem;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            opacity: .5;

            &.active {
                opacity: 1;
            }
        }
    }
    .items {
        display: flex;
        overflow: hidden;
        gap: 1.6rem;
        margin-top: 3.2rem;

        .item,
        label,
        a {
            scroll-snap-align: unset;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
        }

        a.slider {
            width: calc(23% - 1.6rem);
            aspect-ratio: 345.2 / 222;
        }
    }
}

body.partner {
    .slider-container {
        .items {
            a.slider {
                width: calc(25% - 1.2rem);
            }
        }
    }
}

.horizontal-scroll {
    width: 100%;

    article {
        display: grid;
    }
}