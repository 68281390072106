
.hero-animation {
    position: absolute;
    width: 70%;
    top: 26px;
    bottom: 26px;
    grid-template-rows: 0.8% 8.9% 22.9% 4.5% 26.5% 4.6% 13.7% 11.6% 6.5%;
    z-index: -1;

    &.hero--left {
        left: calc(-35% - 42px);

        grid-template-columns: 2.4% 32.8% 2.6% 4.8% 2.5% 3.1% 2.9% 4.6% 2.7% 19.7% 2.7% 5.5% 12.1% 1.6%;

        .A7 {
            grid-column-start: 1;
            grid-column-end: 5;
        }
        .B2 {
            grid-column-start: 2;
            grid-column-end: 3;
        }
        .D1 {
            grid-column-start: 4;
            grid-column-end: 7;
        }
        .D5 {
            grid-column-start: 4;
            grid-column-end: 9;
        }
        .F7 {
            grid-column-start: 6;
            grid-column-end: 11;
        }
        .H3 {
            grid-column-start: 8;
            grid-column-end: 13;
        }
        .J5 {
            grid-column-start: 10;
            grid-column-end: 14;
        }
        .L7 {
            grid-column-start: 12;
            grid-column-end: 15;
        }
    }
    &.hero--right {
        right: calc(-35% - 42px);

        grid-template-columns: 1.6% 12.1% 5.5% 2.7% 19.7% 2.7% 4.6% 2.9% 3.1% 2.5% 4.8% 2.6% 32.8% 2.4%;

        .A7 {
            grid-column-start: 11;
            grid-column-end: 15;
        }
        .B2 {
            grid-column-start: 13;
            grid-column-end: 14;
        }
        .D1 {
            grid-column-start: 9;
            grid-column-end: 12;
        }
        .D5 {
            grid-column-start: 7;
            grid-column-end: 12;
        }
        .F7 {
            grid-column-start: 5;
            grid-column-end: 10;
        }
        .H3 {
            grid-column-start: 3;
            grid-column-end: 8;
        }
        .J5 {
            grid-column-start: 2;
            grid-column-end: 6;
        }
        .L7 {
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }

    .A7 {
        grid-row-start: 7;
        grid-row-end: 10;
    }

    .B2 {
        grid-row-start: 1;
        grid-row-end: 6;
    }

    .D1 {
        grid-row-start: 1;
        grid-row-end: 4;
    }

    .D5 {
        grid-row-start: 5;
        grid-row-end: 6;
    }

    .F7 {
        grid-row-start: 7;
        grid-row-end: 9;
    }

    .H3 {
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .J5 {
        grid-row-start: 5;
        grid-row-end: 6;
    }

    .L7 {
        grid-row-start: 7;
        grid-row-end: 8;
    }

    .card {
        position: relative;

        .item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.7;
            border-radius: $border-radius-default;
            border: 1px solid var(--color);
            background-size: cover;
            background-position: center center;
        }
    }
}

.home--header {
    position: relative;
    font-size: 1.8rem;
    overflow-x: hidden;

    img,
    svg {
        width: 80%;
        max-width: 400px;
    }

    text-container {
        max-width: 1280px;
    }

    h2 {
        font-size: 2.4rem;
        span {
            display: block;
        }
        em {
            color: $black;
            border-radius: $border-radius-default;
            background: linear-gradient(45deg, $default-color 0%, $default-color-hover 100%);
            padding: 8px 16px;
            transform: rotate(-2deg);
            font-style: normal;
            display: inline-block;
            width: auto;
            flex-grow: 0;
            flex-shrink: 0;
        }

    }
}

.home-b2c {
    // overflow-x: hidden;

    h2.access {
        em {
            color: $black;
            -webkit-text-fill-color: $black;
            background: linear-gradient(50.6deg, #F949F3 -25.31%, #FBBA17 93.3%);
        }
    }
}

.home--partners {
    width: 100%;
    height: 300vh;
    // position: relative;
    background-image: linear-gradient(0deg, #03023B 0%, rgba(3, 2, 59, 0) 70%);
    // overflow: hidden;
    background-image: url('/images/home/background.webp');
    background-position: top center;
    background-size: cover;

    .home--partners--content {
        position: sticky;
        text-align: center;
        margin: 0;
        top: 0;
        margin-top: 15vh;
        padding-top: 45vh;
        padding-bottom: 25vh;
        // transform: translateY(-20%);
    }

    // .home--partners--image {
    //     width: 100%;
    //     height: 100%;
    //     z-index: -1;
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     transform: translate(-50%,0);
    //     pointer-events: none;
    //     object-fit: cover;
    // }
}



// .home--partners {
//     transform-origin: center top;
//     transform: scaleY(1);
//     height: 300vh;

//     > * {
//         transform-origin: center top;
//         transform: scaleY(1);
//     }

//     .home--partners--image-container {
//         position: sticky;
//         top: 0;
//         width: 100vw;
//         height: 100vh;
//         z-index: -2;
//     }

//     .home--partners--image-container img {
//         display: block;
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//     }

//     .home--partners--content {
//         background-image: linear-gradient(360deg, #03023B 60%, rgba(3, 2, 59, 0) 100%);
//         position: absolute;
//         z-index: -1;
//         width: 100%;
//         bottom: 0;
//         padding-top: 40px;
//     }
// }

.home--how-it-works-cta {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.tabs-how-it-works--cards {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    gap: 2.4rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    h3 {
        font-size: 2rem;
        font-weight: $font-weight-bold;
    }

    .home--how-it-works--card {
        scroll-snap-align: start;
        display: inline;
        flex-shrink: 0;
        width: 86%;
        border-radius: $border-radius-default;
        padding: 1px;
        background: linear-gradient(#fff 0%, rgba(255, 255, 255, 0) 100%);

        .home--how-it-works--card--container {
            border-radius: $border-radius-default;
            background: #03023b;
            height: 100%;
        }
    }

    .home--how-it-works--card--image {
        height: 294px;
        text-align: center;
    }
}

#home--gift-cards-ideas {
    .tagline {
        opacity: 75%;
    }

    .items {
        margin-top: 6rem;
        padding-left: 0;
        position: relative;
    }

    .home--gift-cards-ideas--card {
        display: inline-block;
        width: 74vw;
        aspect-ratio: 280/500;
        min-height: 500px;
        background: linear-gradient(var(--default-color) 0%, rgba(255, 255, 255, 0) 100%);
        scroll-snap-align: start;
        flex-shrink: 0;
        border-radius: $border-radius-default;
        padding: 1px;

        img {
            height: 32px;
        }

        a {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            justify-content: end;
            align-items: start;
            padding-bottom: 24px;
            padding-inline: 16px;
            border-radius: $border-radius-default;
        }

        h3 {
            font-weight: $font-weight-bold;
            font-size: 3rem;
        }

        svg {
            height: 25px;
        }

        span {
            color: var(--default-color);
            font-weight: $font-weight-bold;
            visibility: hidden;
            opacity: 0;
            height: 0;
        }

        &:hover {
            @extend %transition-slow;

            span {
                visibility: visible;
                opacity: 1;
                height: auto;
                @extend %transition-slow;
            }
        }
    }
}

.home--experience {
    p {
        opacity: 75%;
    }
}

#testimonials {
    width: auto;

    &.slider-container {
        .item {
            align-items: unset;
        }
    }
}

.home--activate {
    background: linear-gradient(70.2deg, rgba(0, 0, 30, 0.7) 0%, rgba(0, 26, 56, 0.7) 100%);
}

.home--ctas {
    .btn {
        color: $black;
    }
}