.modal-dialog {
    width: 70rem;
    margin-top: 7.2rem;

    &.large {
        // min-width: 960px;
        width: 1024px;
    }
}

.modal-header {
    margin-top: unset;

    button {
        top: 3.2rem;
    }
    
}

.modal-footer {
    button {
        width: 24rem;
    }
}