.testimonials {
    align-items: stretch;

    .testimonial {
        width: 100%;
        height: auto !important;
    }

    .person {
        border-top-left-radius: $border-radius-default;
        border-top-right-radius: $border-radius-default;
        height: 15rem;
        background-size: cover;
        background-position: center center;

        .logo {
            // background: linear-gradient(70.2deg, rgba(255, 255, 255, 0.2) 0%, rgba(247, 247, 247, 0.2) 100%);
            // backdrop-filter: blur(50px);
            // border-radius: $border-radius-default;
            width: 70px;
            height: 70px;
            padding: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .universe {
        background: linear-gradient(45deg, var(--color-start) 0%, var(--color-end) 100%);
        height: 3rem;
        border-radius: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.6rem 1.2rem;
        gap: 0.8rem;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;

        img {
            height: 2.4rem;
        }
    }

    span {
        em {
            color: $primary-color;
            font-style: normal;
        }
    }
}
