.rotated-card-image-container {
    z-index: 1;
    margin-top: 2rem;
    width: 80%;
    transform: rotate(-3deg);
    position: relative;

   &.inverted {
    transform: rotate(3deg);
   }

    svg,
    img {
        width: 100%;
    }

    .fillMe {
        fill: var(--card-color);
    }
    .strokeMe {
        stroke: var(--card-color);
    }

    .rotated-card-image-price {
        position: absolute;
        right: 2rem;
        bottom: 4.6rem;
        text-align: right;
        transform: rotate(-3deg);
        font-weight: $font-weight-bold;
        font-size: 4rem;

        .currency {
            opacity: 0.5;
            font-size: 2rem;
        }
    }
    .rotated-card-image-message {
        position: absolute;
        top: 24px;
        right: 24px;
        left: 24px;
        bottom: 8px;
        overflow: auto;
        text-align: left;
        transform: rotate(3deg);
        font-weight: $font-weight-regular;
        font-size: 1.4rem;
        text-align: center;

        i {
            font-style: normal;
            display: block;
            padding-top: 16px;
            font-weight: 700;
            font-size: 1.6rem;
        }
    }
}
