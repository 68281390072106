.slider-container {
    .items {
        // INFO: Pour que le js fonctionne correctement et que les enfants commence au 0 de ce bloc et non au 0 de son parent
        position: relative;
    }
    a.slider {
        border: 1px solid var(--color);
        background: var(--backgroud);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom left;
        border-radius: $border-radius-default;
        position: relative;
    }
}

.infinite-slider-container {
    .navigation {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        button {
            width: 4rem;
            height: 4rem;
            border: .1rem solid var(--default-color);
            background: transparent;
            border-radius: 4rem;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            opacity: 1;
        }
    }

    .viewport {
        overflow: hidden;
        position: relative;
    }

    .items {
        position: absolute;
        display: flex;
        align-items: center;
        padding: 0;
    }

    .item {
        float: left;
        margin-inline: 1.6rem;
    }
}

.horizontal-scroll {
    width: 100%;
    overflow: hidden;

    article {
        gap: 1.6rem;
    }

    .navigation {
        flex-direction: row;
        justify-content: center;
        margin-top: 2rem;
        gap: .4rem;
        li {
            display: inline;
            a {
                display: inline-block;
                width: 1rem;
                height: 1rem;
                background: transparent;
                border: .1rem solid var(--default-color);
                border-radius: 1rem;
                @extend %transition;

                &.active {
                    background-color: var(--default-color);
                }
            }
        }

    }
}