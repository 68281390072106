.footer-links {
    &.md:grid-cols-6 {
        grid-template-columns: repeat(6, auto);
    }
    &.md:grid-cols-4 {
        grid-template-columns: repeat(4, auto);
    }
}

footer {
    .payment {
        img {
            max-width: 8rem;
        }
    }

    .copy {
        li {
            padding-left: 0;
            display: inline;
            white-space: nowrap;
        }
    }
}