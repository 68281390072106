.btn.close {
    opacity: 0.75;
    padding: 0;
    width: 2.4rem;
    height: 2.4rem;
    padding-inline: 0;

    img {
        width: 2.4rem;
        margin: 0;
        padding: 0;
    }

    &.right {
        right: 3rem;
        top: 4rem;
    }

    &.large {
        right: calc((100vw - 82vw)/2);
        top: 2rem;
        width: 3.2rem;
        height: 3.2rem;

        img {
            width: 3.2rem;
            margin: 0;
            padding: 0;
        }
    }

    &.right,
    &.large {
        position: absolute;
    }
}