@import './utils/mixin.scss';
@import './utils/extends.scss';
@import './utils/animations.scss';

.gradientBg {
    @extend %gradientBackground;
}

.smallRadius {
    border-radius: $border-radius-small;
}

.full-width {
    width: 100%;
}

.strong {
    font-weight: $font-weight-bold;
}

.semi-strong {
    font-weight: $font-weight-semibold;
}

.regular {
    font-weight: $font-weight-regular;
}

.small {
    font-size: 1.4rem;
    font-weight: $font-weight-medium;
}

.underline {
    text-decoration: underline;
}

.uppercase {
    text-transform: uppercase;
}

.cursor {
    cursor: pointer;
}

// With White Border Radius
.wwbr {
    border-radius: $border-radius-default;
    border: 1px solid $white-accent;
}

.radius-tl {
    border-top-left-radius: $border-radius-default;
}
.radius-tr {
    border-top-right-radius: $border-radius-default;
}
.radius-bl {
    border-bottom-left-radius: $border-radius-default;
}
.radius-br {
    border-bottom-right-radius: $border-radius-default;
}