.testimonials {
    .testimonial {
        width: calc(60% - 1.6rem);
        grid-template-columns: 40% 60%;
        // height: 48rem;
    }

    .person {
        border-top-left-radius: $border-radius-default;
        border-top-right-radius: 0;
        height: 100%;
        width: auto;

        &.b2b {
            border-bottom-left-radius: $border-radius-default;
        }

        .logo {
            width: 100px;
            height: 100px;
            padding: 12px;
        }
    }
    .bloc {
        height: 100%;
    }
}
