@if $theme == 'lebeaujeu' {
    .itm-logo {
        height: 28px;
    }
}

.navbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    transition: all 500ms ease-out;

    &.scroll {
        background-color: var(--font-color-inverted);
        transition: all 500ms ease-in;
    }
}

.navbar {
    width: 100%;
    transition: all ease-out 250ms;

    &.hide {
        height: 0;
        opacity: 0;
        visibility: hidden;
        transition: all ease-out 500ms;
    }

    // INFO: Picto burger
    .navbar-burger {
        padding: 0;
        margin: 0;
        font-size: 1em;
        appearance: none;
        background: none;
        border: none;
        cursor: pointer;
        display: block;
        height: 2.4rem;
        position: relative;
        width: 2.4rem;
        color: var(--font-color);
        margin-right: 1.6rem;

        span {
            background-color: currentColor;
            display: block;
            width: 2rem;
            height: .2rem;
            left: calc(50% - 1rem);
            position: absolute;
            transform-origin: center;
            transition-duration: 86ms;
            transition-property: background-color,opacity,transform;
            transition-timing-function: ease-out;
            border-radius: $border-radius-xsmall;

            &:nth-child(1) {
                top: calc(50% - .6rem);
            }
            &:nth-child(2) {
                top: calc(50% - .1rem);
            }
            &:nth-child(3) {
                top: calc(50% + .4rem);
            }
        }
    }

    // INFO: Contenu de la navbar
    .navbar-brand {
        position: relative;
        width: 90vw;
        max-width: 1280px;
        height: 100%;
        height: 6.2rem;
        margin: 0 auto;
    }

    // INFO: Navbar permettant l'affichage des types de clients + choix de la langue
    &.banners-container {
        font-size: 1.1rem;
        @extend %transition-slow;

        &:not(.hide) {
            height: 5rem;
        }
        .banners {
            position: relative;
            width: 100%;
            height: 4rem;
            display: flex;
            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 4rem;
                animation: fadeOut 1.5s ease-out;

                &.is-active {
                    opacity: 1;
                    z-index: 1;
                    animation: fadeIn 1.5s ease-in;
                }
            }
        }

        .btn-transparent {
            padding: 0;
        }
    }
    &.banners-container,
    &.light {
        .navbar-brand {
            height: 100%;
        }
    }

    // INFO: Boutons de premier niveau
    .navbar-item {
        @extend %transition;

        // INFO: Le bouton en lui même
        .navbar-link {
            position: relative;
        }

        &.has-dropdown {
            padding: 0;
            height: 100%;

            .navbar-link {
                height: 100%;
                line-height: 5rem;
                cursor: pointer;

                // &::before {
                //     position: absolute;
                //     bottom: calc(50% - 1.6rem);
                //     content: "";
                //     width: 0;
                //     background: var(--default-color);
                //     height: .2rem;
                //     @extend %transition-slow;
                // }

                &::after {
                    margin-left: 1rem;
                    content: url('/images/icons/arrow-down.svg');
                    display: inline-block;
                    width: .8rem;
                    @extend %transition;
                }
            }

            .navbar-dropdown {
                position: absolute;
                top: 0;
                @extend %transition;
            }

            .navbar-dropdown--right {
                right: 0;
                background-color: var(--font-color-inverted);
            }

            &:hover {
                .navbar-link {

                    &::after {
                        transform: rotate(180deg);
                    }

                    &::before {
                        width: calc(100% - 1.8rem);
                    }
                }

                .navbar-dropdown {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .btn-icon {
        border-color: rgba(255, 255, 255, .5);
        min-width: 5rem;
        padding: 0 0 0 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .wallet {
            span {
                margin-right: 0.6rem;
                color: var(--accent-color);
            }
        }

        &.isActive,
        &:hover {
            background: $white;
            color: var(--font-color-inverted);

            svg {
                path {
                    fill: var(--font-color-inverted);
                }
            }

            .wallet {
                span {
                    color: var(--font-color-inverted);
                }
            }
        }
    }

    .addCredit {
        aspect-ratio: 1/1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 1px solid rgba(255, 255, 255, .5);
        cursor: pointer;
    }

    .btn-outline-primary {
        background: transparent;
    }
}

// INFO: Logo du site
.navbar-logo {
    svg {
        // height: 3rem;
        height: 100%;

        .fill {
            fill: var(--accent-color);
        }
        .stroke {
            stroke: var(--accent-color);
        }
    }
}

// INFO: Sélection du type de visiteur
.navbar-usertypes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 6rem;

    div {
        opacity: .5;
        border-bottom: solid .4rem transparent;
        font-weight: $font-weight-regular;

        &.is-active {
            opacity: 1;
            font-weight: $font-weight-bold;

            &.b2c {
                border-bottom-color: var(--accent-color);
            }
            &.b2b {
                border-bottom-color: $secondary-color;
            }
        }

        a {
            color: var(--font-color);
        }
    }
}

.navbar-basket {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    flex-direction: column;
    align-items: center;

    button {
        margin: 0;
    }

    .icon {
        position: relative;
        width: 3rem;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        span {
            position: absolute;
            top: 0.2rem;
            right: -0.2rem;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 100%;
            background-color: var(--accent-color);
            font-size: 0.7rem;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.navbar-universes {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    // position: relative;
    flex-direction: column;
    align-items: center;

    button {
        margin: 0;
    }
}

#navbar,
#menu-universes {
    background-color: var(--font-color-inverted);
    background-image: var(--global-bg);
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 11;
    @extend %transition;

    &.isVisible {
        visibility: visible;
        opacity: 1;
        @extend %transition;
    }
}

#menu-universes,
.navbar-universes-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
        margin-top: 3rem;
    }

    ul {
        width: 82vw;
        padding: 0;
        max-width: 1280px;
    }

    li {
        border-radius: $border-radius-small;
        // background: var(--card-color);
        // background: radial-gradient(ellipse at center right, var(--card-color) 0, rgba(255, 207, 122, 0) 50%, rgba(0, 0, 30, 0.6) 100%);
        // background: radial-gradient(circle, var(--card-color) 0%, rgba(255, 207, 122, 0) 50%, rgba(0, 0, 30, 0.6) 100%);
        // background-image: radial-gradient(circle at center right, var(--card-color) 0, rgba(255, 207, 122, 0) 80%, rgba(3, 2, 59, 0.4) 100%);
        background-image: radial-gradient(circle at center right, var(--card-color) 0, rgba(255, 207, 122, 0) 50%, rgba(3, 2, 59, 0.4) 100%);
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        // background-position: top right;

        border: 1px solid var(--card-border);
        list-style: none;
        @extend %transition;

        &:hover {
            background-image: radial-gradient(circle at center right, var(--card-color-hover) 0, rgba(255, 207, 122, 0) 80%, rgba(3, 2, 59, 0.4) 100%);
            // background: var(--card-color-hover);
            @extend %transition;
            img {
                margin-top: 0;
                margin-bottom: 0.8rem;
                @extend %transition-slow;
            }
        }
    }

    a {
        width: 100%;
        height: 100%;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
    }

    img {
        margin-top: 0.8rem;
        width: 2rem;
        @extend %transition;
    }
}
#menu-universes {
    justify-content: center;
}

.menu-universes-all {
    min-height: 15rem;
}

.navbar-universes-container {
    .menu-universes-all {
        min-height: unset;
    }
}

// INFO: Menu en mode mobile (menu ouvert)
#navbar {
    overflow-y: auto;

    // INFO: Bouton permettant de fermer le menu
    .close-container {
        padding: 0 2.4rem;
        height: 6.2rem;

        button {
            margin-right: 1.6rem;
        }
    }

    section {
        margin: 3.2rem 2rem 0 2rem;
    }

    h4 {
        margin-bottom: .8rem;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        position: relative;
        list-style: none;

        img {
            margin-right: 1.2rem;
        }

        a {
            font-weight: $font-weight-regular;
            color: rgba(247, 247, 247, 1);
        }
    }

    .border-bottom {
        border-bottom: 0.1rem solid rgba(247, 247, 247, 0.4);
    }

    .fixed-height {
        height: 6.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }


    .cta-universe {
        img {
            width: 2.4rem;
        }

        a {
            width: 100%;

            &::after {
                position: absolute;
                right: 0;
                content: "";
                background-image: url('/images/icons/chevron-right.white.svg');
                background-size: cover;
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }

    .with-arrow {
        cursor: pointer;

        &::after {
            position: absolute;
            right: 0;
            content: url('/images/icons/arrow-down.svg');
            display: inline-block;
            width: .8rem;
            @extend %transition;
        }

        &.open {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
}

.navbar-basket-container {
    @extend %transition-slow;

    background: $white;
    border-radius: $border-radius-default;
    margin-top: 2.3rem;
    padding: 1.5rem;
    z-index: 5;

    li {
        a {
            color: var(--font-color-inverted);
            width: 100%;
            display: grid;
            grid-template-columns: 1.2rem auto 2.4rem;
            gap: 1.5rem;

            .type {
                font-weight: $font-weight-bold;
            }
            .details {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                opacity: .5;
                font-size: 1.4rem;
            }
            .go {
                display: flex;
                align-items: center;
            }
        }

        &.separant {
            height: 0.1rem;
            background: #F2F2F2;
            margin: 1rem 0;
        }
    }

    .basket-icon {
        width: 1.2rem;
        margin-top: 0.5rem;
        path {
            fill: var(--font-color-inverted);
        }
    }
}

.navbar-universes-container {
    @extend %transition-slow;

    position: absolute;
    left: 0;
    overflow: auto;
    background: $white;
    // border-radius: $border-radius-default;
    padding: 1.5rem;

    background-color: var(--font-color-inverted);
    background-image: var(--global-bg);
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
    // position: fixed;
    // top: 0;
    // min-height: calc(100vh - 18rem);
    width: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 11;

    width: 100%;
}

#navbar-basket {
    position: fixed;
    bottom: -200px;
    left: 0;
    right: 0;
    height: 0;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &.isVisible {
        height: auto;
        bottom: 0;
        @extend %transition-slow;
    }
}