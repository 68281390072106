%transition {
    transition: all 200ms ease-in 0s;
}

%transition-slow {
    transition: all .35s ease-in-out;
}

%gradientLine {
    height: .2rem;
    background: linear-gradient(90deg, var(--default-color) 0%, transparent 100%);
}

%animated-underline {
    position: relative;

    &:after {
        content: '';
        width: 0;
        position: absolute;
        bottom: -.4rem;
        left: 0;
        @extend %gradientLine;
        @extend %transition-slow;
    }

    &:hover {
        &:after {
            width: 100%;
        }
    }
}

%gradientBackground {
    background: linear-gradient(70.2deg, rgba(255, 255, 255, 0.1) 0%, rgba(247, 247, 247, 0.1) 100%);
}

%gradientBackgroundAlternate {
    background: linear-gradient(70.2deg, rgba(0, 0, 30, 0.5) 0%, rgba(0, 26, 56, 0.5) 100%);
}

%gradientWidthBackdrop {
    @extend %gradientBackgroundAlternate;
    backdrop-filter: blur(50px);

}
