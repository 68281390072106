.slider-container {
    .items.bought-card-items {

        .item {
            margin-top: 13rem;
            width: calc(50%);
        }

        .rotated-card-image-container {
            max-width: 45rem;
            margin-top: -12rem;
        }
    }

    a {
        width: auto;
    }
}

.bought-card-cta {
    display: inline-flex;
}