.basket--main {
    h1 {
        font-size: 5.2rem;
        line-height: 5.2rem;
    }
}

.basket--main {
  grid-template-columns: 58% 42%;
}


.basket--offer--logo {
    margin-block: 0;

    @if $theme == 'lebeaujeu' {
        width: 15rem;
    }
}
@if $theme == 'lebeaujeu' {
    .basket--offer--name {
        font-size: 2rem;
    }
    .basket--offer--summary {
        font-size: 1.6rem;
    }
}

.basket--offer--details,
.basket--card--details {
    width: auto;
}

.basket--card--details {
    margin-right: 2rem;
}

.basket--offer--quantity {
    position: relative;
    right: initial;
    bottom: initial;
    margin-left: auto;
    margin-right: 2rem;

    &.bottom {
        align-self: flex-end;
        margin-right: 0;
    }
}

.basket--card--remove {
    button {
        top: calc(50% - 1.2rem);
    }
}

.basket--items {
    .basket--card {
        width: 100%;
    }
}

.basket--offer--access {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
}