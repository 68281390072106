.contact-items {
    img {
        width: 2rem;
    }

    a.link {
        color: $light-blue;
        text-decoration: underline;
    }
    a.phone {
        font-size: 2rem;
    }
}

.contact {
    .phone-note {
        color: $white-accent;
        opacity: 70%;
    }

    h4 {
        font-size: 3rem;
        em {
            color: $light-blue;
            font-style: normal;
        }
    }
}