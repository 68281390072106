body.body-unboxing {
    main {
        height: 80vh;
        margin-inline: auto;
        margin-top: 10vh;
        margin-bottom: unset;
    }
}

.unboxing-container {
    .preview {
        top: 2.7rem;
        left: 2.8rem;
    }
}