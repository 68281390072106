main.invitation {
    .header {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:after {
            bottom: -4rem;
        }
    }


    .rotated-card-image-container {
        width: 50%;
        margin-inline: auto;
        margin-top: -4rem;
    }

    .how-it-works {
        svg {
            width: 32px;
        }
    }
}
