.input-bloc {
    position: relative;
    // margin: 0 2rem 2rem 2rem;

    .input-info,
    .input-error,
    .input-success {
        position: relative;

        font-size: 1.2rem;
        @if $theme == 'lebeaujeu' {
            font-size: 1.4rem;
        }
        padding-left: 2rem;
        opacity: .7;

        &:not(.hidden) {
            display: block;
        }
    }

    .input-info {
        &::before {
            background-image: url('/images/icons/exclamation.svg');
            background-size: cover;
            width: 1.6rem;
            height: 1.6rem;
            content: "";
            left: 0;
            position: absolute;
        }
    }

    .input-error {
        color: $input-error;

        &::before {
            background-image: url('/images/icons/error.svg');
            background-size: cover;
            width: 1.6rem;
            height: 1.6rem;
            content: "";
            left: 0;
            position: absolute;
        }
    }

    .input-success {
        color: #12CF06;

        &::before {
            background-image: url('/images/icons/success.svg');
            background-size: cover;
            width: 1.6rem;
            height: 1.6rem;
            content: "";
            left: 0;
            position: absolute;
        }
    }

    label {
        display: block;
        font-size: 1.4rem;
        line-height: 1.5rem;
        margin-bottom: .8rem;
        position: relative;

        .small {
            font-weight: $font-weight-regular;
        }
    }

    .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        font-size: 1.4rem;
        font-weight: $font-weight-medium;
        margin-bottom: .4rem;

        select,
        textarea,
        input {
            height: 4.2rem;
            padding: 1.2rem 1.6rem;
            border-radius: $border-radius-small;
            background: $light-background;
            display: block;
            position: relative;
            flex: 1 1 auto;
            min-width: 0;
        }

        textarea {
            height: auto;
            min-height: 7rem;
        }

        select {
            appearance: none;
            background-image: url('/images/icons/chevron-down.svg');
            background-position: right 1.6rem center;
            background-repeat: no-repeat;
            background-size: 1.8rem;
        }

        .input-group-text {
            width: auto !important;
            display: flex;
            align-items: center;
            padding: 1.2rem 2rem;
            text-align: center;
            white-space: nowrap;

            .current-country-code {
                position: relative;
                cursor: pointer;
                pointer-events: none;
                margin-left: 3.6rem;
                margin-right: 2.8rem;

                &::after {
                    content: "";
                    position: absolute;
                    right: -2.6rem;
                    background-image: url('/images/icons/chevron-down.svg');
                    background-position: right .4rem center;
                    background-repeat: no-repeat;
                    background-size: 1.8rem;
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }

            &.country {
                padding: 0;
                position: relative;
                line-height: initial;
                select {
                    position: absolute;
                    width: 100%;
                    opacity: 0;
                    background: transparent;
                    appearance: none;
                }
            }

            &.input-group-floating {
                position: absolute;
                display: flex;
                align-items: center;
                right: 0;
                height: 4.2rem;
                z-index: 1;
            }

            &.btn {
                min-height: unset;
                font-size: 1.4rem;
                height: 4.2rem;
            }

            &.input-group-text-before {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.with-after-bloc {
            select,
            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .input-group-text {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.with-before-bloc {
            select,
            input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .input-group-text {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }


    .switch {
        display: inline-block;
        position: relative;
        width: 5rem;
        height: 2.5rem;
        cursor: pointer;
        overflow: hidden;
        opacity: 1;

        input {
            position: absolute;
            top: -30px;
            left: -30px;
            width: 0;
            height: 0;
        }

        input + span {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(224, 224, 224, 1);
            box-shadow: 0 0 .4rem 0 $box-shadow inset;
            border-radius: 5rem;
        }

        input:checked + span {
            background: var(--default-color);
        }

        input + span:before {
            content: "";
            display: inline-block;
            position: absolute;
            left: .2rem;
            width: 2.1rem;
            height: 2.1rem;
            background: var(--font-color);
            border-radius: 100%;
            transition: all .5s;
            box-shadow: 0 .4rem .4rem 0 $box-shadow;
            top: .2rem;
        }

        input:checked + span:before {
            left: calc(5rem - 2.3rem);
        }
    }

    .flag {
        position: absolute;
        left: .8rem;
        height: 4.2rem;
        display: flex;
        align-items: center;

        img {
            width: 2.1rem;
            padding: 0;
        }
    }

    &.error {
        input,
        select {
            border: 1px solid $input-error;
            color: $input-error;
        }
    }

    &.confirm-code,
    &.card-confirm-code {
        .input-group {
            display: block;
        }
        label {
            visibility: hidden;
            display: none;
            width: 0;
            height: 0;
        }
    }

    &.confirm-code {
        input {
            border-radius: $border-radius-small;
            width: 7rem;
            height: 8rem;
            text-align: center;
        }
    }

    &.card-confirm-code {
        input {
            border-radius: $border-radius-small;
            width: 32rem;
            height: 5rem;
            text-align: center;
        }
    }

    &.blur {
        input {
            color: transparent;
            text-shadow: 0 0 0.7rem #F7F7F7;
        }
    }
}

.form-check {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 1.8rem;
    position: relative;

    input {
        opacity: 0;
        width: 0;
    }

    label {
        margin: 0 0 0 1.8rem;

        &::before,
        &::after {
            content:'';
            display: block;
            position: absolute;
            border-radius: $border-radius-xsmall;
        }

        &::before {
            left: 0;
            border: .1rem solid var(--font-color);
            padding: .2rem;
            margin-top: .2rem;
            width: 1.4rem;
            height: 1.4rem;
        }

        &::after {
            left: .2rem;
            top: .4rem;
            background: transparent;
            padding: .2rem;
            width: 1rem;
            height: 1rem;
        }
    }

    input:checked + label::after {
        background: var(--font-color);
    }

    &.rounded,
    &.rounded-icon,
    &.vertical {
        label {
            &::before,
            &::after {
                border-radius: 100%;
            }
        }
    }

    &.rounded-icon {
        label {
            margin-left: 2.6rem;
        }

        input:checked + label::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="url(%23a)" d="M10.011 1.666a8.333 8.333 0 1 0 0 16.667 8.333 8.333 0 1 0 0-16.667Zm3.333 5.833c.214 0 .436.072.6.235a.858.858 0 0 1 0 1.198l-3.125 3.099c-.96.958-2.425.815-3.178-.313l-.833-1.25a.853.853 0 0 1 .235-1.172.853.853 0 0 1 1.171.235l.834 1.25c.168.252.384.292.598.077l3.1-3.124a.845.845 0 0 1 .598-.235Z"/><defs><linearGradient id="a" x1="1.678" x2="18.344" y1="18.333" y2="1.666" gradientUnits="userSpaceOnUse"><stop stop-color="%23FCB83E"/><stop offset="1" stop-color="%23FFCF7A"/></linearGradient></defs></svg>');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            width: 2.2rem;
            height: 2.2rem;
            padding: 0;
            // top: -0.1rem;
            left: -0.2rem;
        }
    }

    &.rounded {
        input:checked + label::after {
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: var(--default-color);
            width: 1rem;
            height: 1rem;
            left: 0.2rem;
            top: 0.4rem;
        }
    }

    &.vertical {
        label {
            width: 100%;
            text-align: center;
            margin-top: 2.6rem;
            margin-left: 0;

            &::before,
            &::after {
                left: calc(50% - 0.8rem);
                top: 0;
            }
        }

        input:checked + label::after {
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: var(--default-color);
            width: 1.2rem;
            height: 1.2rem;
            left: calc(50% - 0.7rem);
            top: 0.3rem;
        }
    }

    &.horizontal {
        label {
            &::before {
                top: calc(50% - 0.7rem);
            }
        }

        input:checked + label::after {
            top: calc(50% - 0.3rem);
        }
    }
}

.input-bloc,
.form-check {
    &.disabled {
        cursor: not-allowed;
        label,
        .btn {
            opacity: 0.3;
        }
    }

    &.error {
        label {
            color: $input-error;
            &::before {
                border: 1px solid $input-error;
            }
        }
    }
}

label {
    a, a:hover, a:active, a:focus, a:link, a:visited {
        text-decoration: underline;
    }
}

select {
    option {
        color: initial;
    }
}

.tooltip {
    display: inline-block;
    position: absolute;
    right: 0;
    svg {
        height: 16px;
        opacity: 70%;
    }

    .tooltipText {
        visibility: hidden;
        width: 30rem;
        border-radius: $border-radius-default;
        background: linear-gradient(70.2deg, rgba(0, 0, 30, 0.7) 0%, rgba(0, 26, 56, 0.7) 100%);
        @if $theme == 'lebeaujeu' {
            background: rgba(#000000, 0.7);
        }

        color: #fff;
        font-weight: $font-weight-regular;
        text-align: center;
        padding: 1.6rem;

        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -15rem;

        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover {
        .tooltipText {
            visibility: visible;
            opacity: 1;
          }
    }

    &.bottom {
        .tooltipText {
            bottom: -60px;
        }
    }
}

.iti__dropdown-content {
    background: linear-gradient(70.2deg, rgba(0, 0, 30, 1) 0%, rgba(0, 26, 56, 1) 100%) !important;
    border: none !important;
}
.iti.iti--allow-dropdown.iti--show-flags.iti--inline-dropdown {
    width: 100%;
}
#inputPhoneNumber {
    width: 100%;
}

.loader {
    width: 32px;
    margin-top: 0;
    margin-right: 8px;
    padding: 4px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $white-accent;
    --_m:
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;

    &.zoom {
        width: 64px;
        padding: 8px;
    }
}
@keyframes l3 {to{transform: rotate(1turn)}}