.img-radius {
    border-radius: $border-radius-default;
}

.img-responsive {
    max-width: 100%;
    max-height: 100%;
}

.w-40 {
    width: 40px;
}

.square {
    aspect-ratio: 1/1;
    height: 100%;
    padding: 40px 30px;
}

.square-h {
    aspect-ratio: 1/1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

svg {
    text {
        stroke: var(--color);
        fill: var(--color);
        tspan {
            font-family: $font, sans-serif;
            font-weight: $font-weight-regular;
        }
    }
}

.w-22 {
    width: 22px;
}
.w-24 {
    width: 24px;
}
.w-32 {
    width: 32px;
}