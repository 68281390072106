.b2b--hero {
    min-height: 70vh;
    width: 100%;
    margin-block: 5vh;
    background-image: url('/images/b2b/hero.webp');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    &.background,
    &.background-gradient {
        background-size: cover;
        backdrop-filter: blur(22.0564px);
        margin-block: 0;
        min-height: 80vh;
    }

    &.background {
        background-image: var(--background);
    }

    &.background-gradient {
        background-image: linear-gradient(180deg, rgba(3, 2, 59, 0) 44.93%, #03023B 100%), var(--background);
    }

    &:after {
        bottom: 2rem;
    }
}

.companies {
    .home--header {
        h2 {
            em {
                background: var(--secondary-color);
            }
        }
    }
}



.b2b--home {
    .wip {
        max-width: 80px;
    }

    .sub-bloc {
        svg {
            width: 32px;
        }
    }
}

.b2b--home--targets--cards.slider.item {
    display: inline-block;
    width: 74vw;
    aspect-ratio: 280/500;
    min-height: 500px;
    background: linear-gradient(var(--default-color) 0%, rgba(255, 255, 255, 0) 100%);
    scroll-snap-align: start;
    flex-shrink: 0;
    border-radius: $border-radius-default;
    padding: 1px;

    img {
        height: 32px;
    }

    a {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: linear-gradient(180deg,rgba(3, 2, 59, 0) 0, rgba(3, 2, 59, 0) 50%, #03023B 76%), var(--bg-image);
        justify-content: end;
        align-items: start;
        padding-bottom: 24px;
        padding-inline: 16px;
        border-radius: $border-radius-default;
    }

    h3 {
        font-weight: $font-weight-bold;
        font-size: 3rem;
    }

    span {
        margin-top: 0.4rem;
        color: var(--default-color);
        font-weight: $font-weight-bold;
    }

    &:hover {
        @extend %transition-slow;
    }
}

.b2b--home--customers {
    .item {
        width: 45%;
        aspect-ratio: 191/122;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 36px;
    }
}