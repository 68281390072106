.access-partner {
    @if $theme != 'lebeaujeu' {
        &.partner {
            padding-top: 12rem;
        }
    }
}

.access {
    #showMenuUniverses {
        color: #FA945F;
        border-color: #FA945F;

        svg {
            path {
                stroke: #FA945F;
            }
        }
    }

    p {
        em {
            background: -webkit-linear-gradient(0deg, #F949F3, #FBBA17);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .signin {
        .confirm-offers-logo {
            background: transparent;
            width: 235px;
            height: auto;
            padding: 0;

        }

        .lebeaujeu--image {
            width: 100%;
            background: $white;
            border-radius: $border-radius-default;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .access--card--logo {
        position: absolute;
        top: 13px;
        right: 17px;
        transform: rotate(-3deg);
    }
    .access--amount {
        position: absolute;
        left: calc(50% - 52px/2 - 78.13px);
        bottom: 2px;
        transform: rotate(-3deg);
        font-weight: $font-weight-bold;
        font-size: 3.2rem;

        small {
            opacity: 50%;
            font-size: 2rem;
        }
    }

    .home--how-it-works--card {
        h3 {
            font-size: 1.8rem;
        }
    }

    .slider-container {
        article {
            width: 100%;
        }
    }

    .save-money--partners {
        img {
            margin: 4px;
            width: calc(50% - 16px);
            transform: rotate(-4deg);
            border-radius: $border-radius-default;
        }
    }

    .save-money--amount {
        small {
            font-size: 1.6rem;
        }

        .save-money--amount--large {
            font-weight: $font-weight-semibold;
            font-size: 4rem;
            color: var(--default-color);
        }
    }

    .save-money--old {
        text-decoration: line-through;
    }

    .save-money--about {
        padding: 1.6rem;
    }

    .save-money--person {
        width: 95px;
        margin-right: 1rem;
        border-radius: $border-radius-small;
    }
}

.access--logo {
    width: 55px;
    height: 55px;
    border-radius: 55px;
    padding: 8px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.access--font--style {
    em {
        background: -webkit-linear-gradient(0deg, #F949F3, #FBBA17);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.access--universes--about {
    position: relative;

    .more-icon {
        position: absolute;
        display: block;
        background-image: url('data:image/svg+xml,<svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="28.5" cy="28" r="27.5" fill="%23413A64" stroke="%23FA7A95"/><path d="M28.4928 22.0117C27.9408 22.0117 27.4938 22.4587 27.4938 23.0107C27.4938 23.4887 27.4938 29.0757 27.4938 30.5657L24.4968 27.5997L23.0918 29.0047L27.7748 33.7187C27.9698 33.9147 28.2318 34.0117 28.4938 34.0117C28.7548 34.0117 29.0158 33.9137 29.2108 33.7187L33.8938 29.0047L32.4888 27.5997L29.4918 30.5657C29.4918 29.0757 29.4918 23.4887 29.4918 23.0107C29.4918 22.4587 29.0448 22.0117 28.4928 22.0117Z" fill="%23FFFDF7"/></svg>');
        width: 57px;
        height: 56px;
        bottom: -28px;
        left: calc(50% - 28px);
        cursor: pointer;
        transition: all ease-out 500ms;

        @if $theme == 'lebeaujeu' {
            background-image: url('data:image/svg+xml,<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="28" cy="28" r="27.5" fill="black" stroke="url(%23paint0_linear_5168_59167)"/><rect x="16.0005" y="16.0005" width="23.999" height="23.999" fill="black"/><rect x="16.0005" y="16.0005" width="23.999" height="23.999" stroke="url(%23paint1_linear_5168_59167)" stroke-width="0.001"/><path d="M27.9928 22.0122C27.4408 22.0122 26.9938 22.4592 26.9938 23.0112C26.9938 23.4892 26.9938 29.0762 26.9938 30.5662L23.9968 27.6002L22.5918 29.0052L27.2748 33.7192C27.4698 33.9152 27.7318 34.0122 27.9938 34.0122C28.2548 34.0122 28.5158 33.9142 28.7108 33.7192L33.3938 29.0052L31.9888 27.6002L28.9918 30.5662C28.9918 29.0762 28.9918 23.4892 28.9918 23.0112C28.9918 22.4592 28.5448 22.0122 27.9928 22.0122Z" fill="%23FFFDF7"/><defs><linearGradient id="paint0_linear_5168_59167" x1="0" y1="28" x2="56" y2="28" gradientUnits="userSpaceOnUse"><stop/><stop offset="1" stop-color="%23DA3832"/></linearGradient><linearGradient id="paint1_linear_5168_59167" x1="16" y1="28" x2="40" y2="28" gradientUnits="userSpaceOnUse"><stop/><stop offset="1" stop-color="%23DA3832"/></linearGradient></defs></svg>');
        }
    }

    .btn-more {
        padding-top: 16px;
        display: block;
        cursor: pointer;
    }

    .access--universes--about--more--container {
        visibility: hidden;
        opacity: 0;
        transition: all ease-out 500ms;
        height: 0;
        border-image: linear-gradient(30deg, #F949F3, #FBBA17) 30;
        border-top-width: 1px;
        border-top-style: solid;

        @if $theme == 'lebeaujeu' {
            border-image: none;
            border-color: $default-color;
        }
    }

    &.open {
        .access--universes--about--more--container {
            visibility: visible;
            opacity: 1;
            height: auto;
            padding-block: 3.2rem;

            @if $theme == 'lebeaujeu' {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        .more-icon {
            transform: rotate(180deg);
        }

        @if $theme == 'lebeaujeu' {
            .access--universes--about--container--background {
                border-bottom-left-radius: 0;
            }
        }
    }
}

.access--universes--about--card--container {
    position: relative;
    width: 244px;
    margin-inline: auto;
}

.access--universes--about--card--container--logo {
    position: absolute;
    left: 16px;
    bottom: 16px;
    max-width: 100px;
    max-height: 80px;
    transform: rotate(-3deg);
}

.access--universes--about--more--container {
    .home--how-it-works {
        padding-top: 0;

        ul {
            padding: 0;
        }

        h2 {
            text-align: center;
            font-size: 2rem;
        }

        h3 {
            font-size: 1.8rem;
        }

        .home--how-it-works--card--container {
            padding: 0;
            margin-bottom: 32px;
        }
    }
}

.access--banner {
    width: 100%;
    text-align: center;
    padding: 12px 16px;
    background: linear-gradient(50.6deg, #F949F3 -25.31%, #FBBA17 93.3%);
    color: $black;
    font-weight: $font-weight-bold;

    em {
        color: $black;
        border-radius: $border-radius-default;
        background: $white;
        padding: 8px 16px;
        margin-inline: 8px;
        transform: rotate(-2deg);
        font-style: normal;
        display: inline-block;
        width: auto;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.partner--offers--access-discount {
    background: linear-gradient(50.6deg, #F949F3 -25.31%, #FBBA17 93.3%);
    border-radius: $border-radius-small;
    color: $black;

    em {
        color: $black;
        border-radius: $border-radius-default;
        background: $white;
        padding: 4px 6px;
        margin-left: 0.4rem;
        transform: rotate(-2deg);
        font-style: normal;
        display: inline-block;
        width: auto;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.basket--payment--row--access {
    .label {
        background: -webkit-linear-gradient(0deg, #F949F3, #FBBA17);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .value {
        color: #FBBA17;
    }
}

@if $theme == 'lebeaujeu' {
    .basket--payment--row {
        .value {
            color: $default-color;
        }
    }
}

.btn-access {
    margin: 0;
    padding: 0;
    padding-top: 4px;
    height: auto;
    background: -webkit-linear-gradient(30deg, #F949F3, #FBBA17);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    border: none;
    border-image: linear-gradient(30deg, #F949F3, #FBBA17) 30;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    font-size: 1.6rem;
}

#howItWorks {
    .modal-header {
        font-size: 2.4rem;
        em {
            color: var(--default-color);
            font-style: normal;
        }
    }
    h3 {
        font-size: 2rem;
    }

    ul {
        padding: 0;
    }
}

@include respond-to("medium-up") {
    .access {
        .signin {
            .confirm-offers-logo {
                width: 417px;
            }


            .lebeaujeu--image {
                width: 43%;
                background: $white;
            }
        }

        .access--card--logo {
            top: 20px;
            right: 30px;
        }

        .access--amount {
            position: absolute;
            left: calc(50% - 92px/2 - 137.41px);
            bottom: 16px;
            font-size: 5.8rem;

            small {
                font-size: 4rem;
            }
        }

        .home--how-it-works--card--image {
            height: 26rem;
        }

        .slider-container {
            article {
                width: 45%;
            }
        }

        .save-money--partners {
            img {
                margin: 4px;
                width: calc(33% - 16px);
                transform: rotate(-4deg);
            }
        }

        .save-money--amount {
            small {
                font-size: 2.4rem;
            }

            .save-money--amount--large {
                font-size: 5.2rem;
            }
        }


        .save-money--about {
            padding: 0;
        }

        .save-money--person {
            width: 120px;
            margin-right: 2rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .access--logo {
        width: 88px;
        height: 88px;
        border-radius: 88px;
        padding: 8px;
    }

    .access--universes--about--card {
        order: 2;
        // width: 515px;
        float: right;
    }
    .access--universes--about--card--container {
        width: 405px;
    }

    .access--universes--about--more--container {
        .home--how-it-works {
            h2 {
                font-size: 3rem;
            }

            h3 {
                font-size: 2.4rem;
            }
        }
    }

    .account {
        .access--bloc {
            font-size: 1.8rem;

            strong.zoom {
                font-size: 2.4rem;
            }
        }
    }


    #howItWorks {
        .modal-header {
            font-size: 3rem;
        }
        h3 {
            font-size: 2.4rem;
        }
    }

    .access--universes--about--card--container--logo {
        max-width: 160px;
        max-height: 120px;
    }
}

#selectEnv {
    .select--env--card {
        border-radius: $border-radius-default;
        padding: 2.4rem;
    }
    .defaultEnv {
        border: 1px solid var(--default-color);
    }
    .accessEnv {
        border: 1px solid #FA945F;
    }
}

.abondement {
    .flip-item,
    .offer-item {
        width: 430px;
    }
    .rotated-card-image-container {
        width: 100%;
    }
}