::-moz-selection,
::selection {
    color: var(--font-color);
    background: var(--default-color);
}

*:focus {
    outline: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

html {
    overflow-x: hidden;
    height: 100%;
    font-size: 62.5%;
    line-height: 1.15;
}

body {
    width: 100%;
    min-height: 100vh;
    font-family: $font, sans-serif;
    font-size: 1.6rem;
    line-height: normal;
    background-color: var(--font-color-inverted);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    color: var(--font-color);
    padding-top: 11.2rem;

    @if $theme == 'lebeaujeu' {
        padding-top: 6.2rem;
    }
}

a,
a:hover,
a:active,
a:focus,
a:link,
a:visited {
    color: var(--font-color);
    text-decoration: none;
}

.link-primary,
.link-primary:visited {
    color: var(--default-color);
    font-weight: $font-weight-bold;
    text-decoration: underline;

    &:hover {
        color: var(--default-color-hover);
    }
}

.with-picto {
    background-image: var(--bg-image);
    background-position: center top;
    background-repeat: no-repeat;
    padding-top: 8rem;
    text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--font-color);
  line-height: 100%;
}

h1 {
    font-size: 4rem;
    font-weight: $font-weight-semibold;
    letter-spacing: -.05em;
    text-align: center;
}
h2 {
    font-size: 3rem;
    font-weight: $font-weight-semibold;
    letter-spacing: -.05em;
}
h3 {
    font-size: 4rem;
    font-weight: $font-weight-semibold;
    letter-spacing: -.05em;
}
h4 {
    font-size: 2rem;
    font-weight: $font-weight-semibold;
}
h5 {
    font-size: 2rem;
    font-weight: $font-weight-semibold;
}
h6 {
    font-size: 1.6rem;
    font-weight: $font-weight-semibold;
    letter-spacing: -.05em;
}

header {
    width: 100%;
}

@include respond-to("medium-up") {
    h1 {
        font-size: 6rem;
    }
    h4 {
        font-size: 3rem;
        font-weight: $font-weight-bold;
        letter-spacing: -.05em;
    }
}

ul {
    list-style: none;
}

// INFO: Cas de Windows 10/11 avec son zoom par défaut
@media (min-resolution: 1.25dppx) and (min-width:576px) {
    html {
        font-size: 50%;
    }
}

@media (min-resolution: 1.5dppx) and (min-width:576px) {
    html {
        font-size: 45%;
    }
}

@media (min-resolution: 1.75dppx) and (min-width:576px) {
    html {
        font-size: 40%;
    }
}

// INFO: Rétina, on revient par défaut
@media (min-resolution: 2dppx) {
    html {
        font-size: 62.5%;
    }
}
