footer {
    @if $theme == 'lebeaujeu' {
        background-color: #000;
    } @else {
    background-image: url('/images/backgrounds/footer.svg');
    }
    background-position: top center;
    background-size: cover;

    .footer-border {
        height: .1rem;
        @extend %gradientLine;
    }

    .footer-content {
        width: 90vw;
        margin: 0 auto;
        padding: 8rem 0;
    }

    .logo {
        height: 3rem;
    }

    .social {
        display: flex;
        flex-direction: row;
        column-gap: 1.2rem;
        img {
            height: 2.4rem;
        }
    }

    .copy {
        a,
        span {
            color: rgba(255, 255, 255, .4);
        }

        span {
            font-weight: $font-weight-bold;
        }
    }

    .payment {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 4.5rem;
    }

    .footer-links {
        display: grid;
        grid-gap: 2.4rem;
        padding-bottom: 4rem;
        border-bottom: .1rem solid var(--font-color);

        .title {
            font-size: 2.2rem;
            font-weight: $font-weight-semibold;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            margin-top: 1.6rem;

            a {
                @extend %animated-underline;
            }
        }
    }

    .input-group {
        margin-top: 1.6rem;
    }

    input:not([type="radio"]):not([type="checkbox"]) {
        border: .1rem solid rgba(255, 255, 255, .75);
        padding: 0 2rem;
    }

    svg {
        circle {
            fill: var(--font-color);
        }
        path {
            fill: var(--default-color);
        }
    }
}