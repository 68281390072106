.universes {
    .assets {
        font-size: 1.2rem;
    }

    .giftCard {
        grid-template-columns: 65% 35%;

        h2,
        .subtitle {
            font-size: 6rem;
            line-height: 6rem;
        }
        .subtitle2 {
            font-size: 5rem;
            line-height: 5rem;
        }
        .subtitle3 {
            font-size: 4rem;
            line-height: 4rem;
        }
        // svg {
        //     padding-left: 10rem;
        // }
    }
}

.universes--about-universe {
    background-size: 27%;
    background-position-x: right 3.5rem;
    background-position-y: bottom -16rem;
}

.universes-partners {
    #search {
        margin-right: 0.8rem;
    }
}

#showMenuUniverses {
    display: none;
}

.universes--list--container {
    gap: 0.5rem;

    .btn {
        border-radius: $border-radius-default;
        padding: 0 2rem;

        &.btn-primary {
            color: $black;
        }

        img {
            padding-left: 0;
            height: 2.2rem;
            opacity: 0.75;
        }

        &:hover {
            background-color: var(--hover-color);
        }
    }
}

.universes--list--partners--container {
    .slide-hover {
        position: relative;

        @if $theme == 'lebeaujeu' {
            display: grid;
        } @else {
            &:hover {
                .details {
                    background-image:
                        linear-gradient(180deg, rgba(3, 3, 59, 0) 0%, rgba(3, 3, 59, 0) 40%, var(--font-color-inverted) 80%),
                        var(--bg-image);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    transition: all ease-in 250ms;
                    position: absolute;
                    top: -4rem;
                    right: -4rem;
                    bottom: -4rem;
                    left: -4rem;
                    height: unset;
                    height: calc(100% + 8rem);
                    max-height: unset;
                    z-index: 3;
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    align-items: self-start;
                    border-radius: $border-radius-default;
                    padding-bottom: 3.2rem;
                }
            }

            &:nth-child(4n) {
                &:hover {
                    .details {
                        right: 0;
                        left: -8rem;
                    }
                }
            }
            &:nth-child(4n + 1) {
                &:hover {
                    .details {
                        left: 0;
                        right: -8rem;
                    }
                }
            }
        }
    }
}