.universes {
    li {
        list-style: none;
    }

    h4 {
        gap: 1rem;
    }
    em {
        font-style: normal;
        color: var(--color);
    }

    main {
        ul {
            margin: 0;
            padding: 0;
        }
    }

    .tags {
        color: var(--color);
        text-transform: uppercase;

        li {
            display: inline;
            // white-space: nowrap;
            margin-right: 1rem;
            &:not(:first-child) {
                &::before {
                    display: inline-block;
                    content: "";
                    width: .4rem;
                    height: .4rem;
                    border-radius: $border-radius-small;
                    background: var(--color);
                    margin: 0 1rem .4rem 0;
                }
            }
        }
    }

    .giftCard {
        h2 {
            margin-bottom: 0;
        }
        .subtitle {
            font-size: 4.4rem;
            font-weight: $font-weight-semibold;
            line-height: 4.4rem;
            letter-spacing: -.05rem;
            opacity: 0.7;
        }
        .subtitle2 {
            font-size: 3.8rem;
            font-weight: $font-weight-semibold;
            line-height: 3.8rem;
            letter-spacing: -.05rem;
            opacity: .5;
        }
        .subtitle3 {
            font-size: 3rem;
            font-weight: $font-weight-semibold;
            line-height: 3rem;
            letter-spacing: -.05rem;
            opacity: .3;
        }

        a {
            color: var(--link-color);
        }

        p {
            padding-bottom: 4rem;
        }

        .btn {
            border-color: var(--link-color);
            color: var(--white);
        }

        svg {
            .fillMe {
                fill: var(--color);
            }
        }
    }

    .btn-container {
        display: inline-block;
    }
}

.universes--about-universe {
    background: var(--bg-color);
    background-image: var(--bg-image);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 28%;
    border: 1px solid var(--color);
    border-radius: $border-radius-default;

    h3 {
        em {
            color: var(--color);
        }
    }
}


.universes-partners {
    .input-bloc {
        width: 100%;
    }

    .input-bloc {
        .input-group-text.input-group-floating {
            height: 5.8rem;
            margin-right: 0.8rem;
        }
    }

    #search {
        margin-top: 0.4rem;
        height: 5rem;
        border-radius: .4rem;
        border: .1rem solid rgba(255, 255, 255, .75);
    }

    .showNews {
        width: auto;
        margin-left: 1.6rem;
        min-width: 15rem;

        label {
            order: 2;
            margin: 0;
        }

        .switch {
            order: 1;
        }
    }
}

.h2-picto {
    opacity: 0.75;
    width: 3.3rem;
}