.partner--hero {
    height: calc(100vh - 26rem);

    p {
        &:after {
            margin-left: calc(50% - 11.5rem);
        }
    }
}

.partner--hero--bloc {
    max-width: 106rem;
    margin: 0 auto;
}
.partner--hero--logo {
    max-width: 26rem;
}

.partner--spacer {
    height: 9.3rem;
}

@if $theme != 'lebeaujeu' {
    .partner--offers--offers {
        padding: 0;
    }
}

.partner--offers {
    grid-template-columns: 55% 45%;
}

.partner--offers--card {
    img {
        width: 60%;
    }
}

.how-to-use-code,
.use-before,
.add-notification {
    opacity: 0.75;
}
.use-before,
.add-notification {
    order: 2;
}

.how-to-use-code {
    order: 1;
}
.add-notification {
    cursor: pointer;
}


#relatedPartners {
    h4 {
        font-size: 5.2rem;
        font-weight: $font-weight-semibold;
    }
}

.gallery {
    &.thumbnails {
        grid-template-columns: repeat(4, 1fr);
    }

    &.classic {
        grid-template-columns: repeat(5, 1fr);

        .large--image {
            grid-column: 1/4;
            grid-row: 1/3;
        }
    }
}