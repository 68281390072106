#account--personal--title {
    order: 1;
}
#account-interests--title {
    order: 2;
}
#account--personal--content {
    order: 3;
}
#account-interests--content {
    order: 4;
}

#account--auth--title {
    order: 5;
}
#account--notifications--title {
    order: 6;
}
#account--auth--content {
    order: 7;
}
#account--notifications--content {
    order: 8;
}

#account--submit,
#account--delete {
    order: 9;

    button {
        padding-inline: 4.5rem;
    }
}

.account {
    .bloc {
        padding: 3.2rem;
    }
}

#sponsorship--fund--title {
    order: 1;
}

#sponsorship--fund--content {
    order: 3;

    span {
        margin-top: 2.4rem;
        font-size: 5.2rem;
    }
}

#sponsorship--how-to--title {
    order: 2;
}

#sponsorship--how-to--content {
    order: 4;
}

.account--bills {
    .operation {
        grid-template-columns: 10rem auto auto 7rem;
    }

    .operation--date,
    .operation--label {
        margin: 0;
    }
}

.modal-unlock-offer {
    .purchase-offers {
        img {
            margin-right: 2.6rem;
        }
    }
}

.confirmedShareCode {
    margin-top: 16rem;
    padding: 6rem;

    img {
        height: 20rem;
        top: -14rem;
    }
}

.account--sponsorship {
    .picto {
        width: 4rem;
        height: 4rem;
    }
}