.hero {
    width: 100%;
}

.container {
    margin-left:auto;
    margin-right:auto;
    width: 90vw;
}

.section {
    min-height: 100vh;
}

pre {
    padding: 4rem 8rem 2rem 8rem;
    border-radius: $border-radius-default;
    border: 1px solid var(--default-color);
    text-align: left;
    background: #00001E;
}

.background {
    background-image: url('/images/backgrounds/main/webp/59a3d8.webp');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

body.error {
    .section {
        min-height: auto;

        p {
            margin: 4rem 0;

            a {
                text-decoration: underline;
                font-weight: 700;
            }
        }
    }
}


.bloc {
    border-radius: $border-radius-default;
    background: linear-gradient(70.2deg, rgba(0, 0, 30, 0.7) 0%, rgba(0, 26, 56, 0.7) 100%);
    @if $theme == 'lebeaujeu' {
        background: rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(50px);
    }
}

.light-bloc {
    border-radius: $border-radius-default;
    background: linear-gradient(70.2deg, rgba(255, 255, 255, 0.2) 0%, rgba(247, 247, 247, 0.2) 100%);
}

.sub-bloc {
    border-radius: $border-radius-default;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(52px);
}

.access--bloc {
    background: -webkit-linear-gradient(0deg, #F949F3, #FBBA17);
    @if $theme == 'lebeaujeu' {
        background: transparent;
    }
    border-radius: $border-radius-default;
    padding: 1px;
}

.access--bloc--container {
    background-color: #353562;
    border-radius: $border-radius-default;

    @if $theme == 'lebeaujeu' {
        background: transparent;

        .access--universes--about--container--background {
            border-radius: $border-radius-default;
            background: linear-gradient(90deg, #DA3832 0%, #000000 100%);
            backdrop-filter: blur(50px);
        }

        .zoom {
            text-transform: uppercase;
        }
    }

    .access--bloc--container--border-top {
        border-image: linear-gradient(30deg, #F949F3, #FBBA17) 30;
        border-top-width: 1px;
        border-top-style: solid;
    }

    @if $theme == 'lebeaujeu' {
        border: 1px solid $default-color;
        .lebeaujeu--image {
            background: #fff;
            border-top-left-radius: $border-radius-default;
            border-top-right-radius: $border-radius-default;
        }
    }
}

.relative {
    position: relative;
}

.max-550 {
    max-width: 55rem;

}
.max-768 {
    max-width: 76.8rem;
}
.max-840 {
    max-width: 84rem;
}
.max-center {
    margin-inline: auto;
}

.w-550 {
    width: 55rem;
}
.w-768 {
    width: 76.8rem;
}



.mt--120 {
    margin-top: -12rem;
    z-index: 2;
}
@include respond-to("medium-up") {
    .md\:mt--180 {
        margin-top: -18rem;
        z-index: 2;
    }
    .md\:w-768 {
        width: 76.8rem;
    }

    @if $theme == 'lebeaujeu' {
        .access--bloc--container {
            .lebeaujeu--image {
                border-top-right-radius: 0;
                border-bottom-left-radius: $border-radius-default;
                img {
                    max-height: 290px;
                }
            }
        }
    }
}

.important {
    color: var(--default-color);
    font-weight: $font-weight-bold;
    font-size: 1.4em;
}

.text-light {
    opacity: 0.7;
}

.text-underline {
    text-decoration: underline !important;
}

.cursor-pointer {
    cursor: pointer;
}

.link {
    text-decoration: underline !important;
    font-weight: $font-weight-bold;
}

.disabled-card {
    opacity: 30%;
}