
label.interests--checkbox {
    display: contents;
    position: relative;
    border-radius: $border-radius-default;
    border: .1rem solid var(--color);
    padding: 1.5rem 4rem 1.5rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: end;
    background: radial-gradient(ellipse at top right, var(--color) 0, rgba(255, 207, 122, 0) 50%, rgba(0, 0, 30, 0.6) 100%);
    font-size: 1.8rem;

    span {
        &::after {
            content: "";
            background-image: url('/images/icons/not-selected.svg');
            width: 22px;
            height: 22px;
            position: absolute;
            bottom: 1.5rem;
            right: 1.5rem;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }


    img {
        display: block;
        margin-bottom: .5rem;
        max-width: 2.2rem;
        max-height: 2.2rem;
    }

    input {
        visibility: hidden;
        opacity: 0;
    }

    input:checked + span {
        &::after {
            content: "";
            background-image: url('/images/icons/selected.svg');
            background-repeat: no-repeat;
        }
    }


    &:has(input:disabled) {
        opacity: 0.5;
        cursor: not-allowed;
    }
}