.about {
    h3 {
        font-size: 3rem;
        font-weight: $font-weight-bold;
        letter-spacing: -.05em;

        em {
            font-style: normal;
            color: var(--default-color);
        }
    }

    img {
        width: 100vw;
    }

    p {
        padding-top: 2.6rem;
    }

    .btn-container {
        width: 100%;
    }

    .btn {
        margin-top: 4rem;
        margin-bottom: 7.8rem;
    }
}