@import './home.scss';
@import './partners.scss';
@import './targets.scss';
@import './why.scss';

.b2b--bloc-light {
    background: linear-gradient(94.36deg, rgba(89, 163, 216, 0.5) 0%, rgba(3, 2, 59, 0.5) 100%);
    backdrop-filter: blur(50px);
    border-radius: $border-radius-default;
}

.b2b--yellow--border {
    border: 1px solid var(--primary-color);
}

.b2b--gain--bloc {
    border: 1px solid var(--secondary-color);
    background: rgba(255, 255, 255, 0.1);
    // background: radial-gradient(ellipse at bottom center, var(--primary-color) -100%, rgba(255, 255, 255, 0.1) 50%);
    border-radius: 10px;

    font-weight: $font-weight-bold;

    svg {
        width: 40px;
    }

    h3 {
        font-size: 2.4rem;
    }
}

.b2b--people--bloc {
    svg {
        width: 40px;
    }
}

.b2b--other-solutions--bloc {
    background-color: rgba(255, 255, 255, 0.1);
    background-image: var(--background);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: $border-radius-default;
    border: 1px solid var(--secondary-color);
}