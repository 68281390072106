footer {
    .social {
        justify-content: flex-end;
    }

    // .footer-links {
    //     .title {
    //         min-height: 6rem;
    //     }
    // }

    .payment {
        justify-content: end;
    }
}