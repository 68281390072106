.slide-hover {
    position: relative;
    display: flex;

    &.with-background {
        height: 50rem;
        border-radius: $border-radius-small;
        background-image: linear-gradient(180deg, rgba(3, 3, 59, 0) 50%, var(--font-color-inverted) 100%), linear-gradient(213.46deg, rgba(3, 3, 59, 0.2) 0%, rgba(3, 3, 59, 0) 13.2%), var(--bg-desktop);

        // .logo,
        .logo-hover {
            width: 10rem;
        }
    }

    &.with-gradient {
        aspect-ratio: 345/222;
    }

    ul {
        text-align: left;
    }

    .btn {
        margin-bottom: 2.4rem;
        padding: 0;
        width: 66%;
        max-width: 20.5rem;
    }

    .details {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all ease-out 250ms;
        max-height: 0;
        overflow: hidden;
    }

    &:hover {
        background-image: linear-gradient(180deg, rgba(3, 3, 59, 0) 19.5%, var(--font-color-inverted) 57.19%), linear-gradient(209.85deg, rgba(3, 3, 59, 0.4) 0.47%, rgba(3, 3, 59, 0) 13.14%), var(--bg-desktop);

        .pictos {
            visibility: hidden;
            opacity: 0;
        }

        .logo {
            display: none;
        }

        .details {
            transition: all ease-in 750ms;
            max-height: 100%;
        }

        img.partner--logo {
            margin-bottom: 1.6rem;
            max-height: 2.4rem;

            &.portrait {
                max-height: 4rem;
            }
        }
    }
}