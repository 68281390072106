
.flip-item {
    margin-bottom: 0;
    background-color: rgba(0,0,0,0);
    height: 300px;
    cursor: pointer;

    .rotated-card-image-container {
        position: initial;
    }

    .flip-item-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: all .8s ease-in-out;
        transform-style: preserve-3d;

        .flip-item-front,
        .flip-item-back {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        .flip-item-back {
            transform: rotateY(180deg);
        }
    }
    &.active {
        .flip-item-inner {
            transform: rotateY(180deg);
        }
    }

    &.animation {
        .flip-item-inner {
            animation-duration: 0.75s;
            animation-name: flipCard;
            animation-direction: alternate;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes flipCard {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(25deg);
    }
}
