.lateral--slider {
    width: 800px;
}
.lateral--container {
    min-height: 260px;
    background: #fff linear-gradient(167deg, var(--color) 50%, var(--light) 93%);

    .img--container {
        width: auto;
        height: 100%;
        position: relative;
        float: left;
    }

    .text--container {
        font-size: 1.8rem;
        border-top-right-radius: $border-radius-large;
        height: 100%;
    }
}