.b2b--hero {
    min-height: 650px;
    background-image: url('/images/b2b/partners/hero-mobile.webp');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
}

.b2b--partners--sliders {
    align-items: stretch;

    .b2b--partners--slider {
        width: 100%;
    }

    ul {
        width: 100%;
        overflow: hidden;
    }

    .partner {
        width: 110px;
        height: 70px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
    }

    .b2b--partners--slider-universe {
        height: 315px;
        background-position: center center;
        background-size: 600px auto;
        background-repeat: no-repeat;

        em {
            color: var(--color);
        }

        p {
            font-size: 2.4rem;
        }
    }
}

.b2b--customers {
    .customer {
        background: rgba(0, 0, 30, 0.4);
        backdrop-filter: blur(27.6899px);
        border-radius: $border-radius-small;
        aspect-ratio: 191/122;
        width: 20rem;
        padding: 3.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}