@include respond-to("small") {
    @import "./small/auth.scss";
}

@include respond-to("small-up") {
    @import "./small-up/auth.scss";
    @import "./small-up/footer.scss";
}

@include respond-to("medium") {
    @import "./medium/bloc-slider.scss";
    @import "./medium/navbar.scss";
    @import "./medium/universes.scss";
    @import "./medium/auth.scss";
}

@include respond-to("medium-up") {
    body {
        padding-top: 18rem;

        @if $theme == 'lebeaujeu' {
            padding-top: 8rem;
        }
    }

    .with-picto {
        padding-top: 4.7rem;
    }

    .container-column {
        width: 90vw;
        max-width: 1280px;
        margin-left:auto;
        margin-right:auto;
    }

    .btn {
        padding-inline: 1.6rem;
    }

    .md\:btn-auto {
        width: auto !important;
    }

    .md\:btn-center {
        margin-inline: auto;
    }

    h2 {
        font-size: 5.2rem;
        line-height: 5.2rem;
    }

    .btn.close.large {
        top: 4rem;
    }

    .md\:order1 {
        order: 1;
    }
    .md\:order2 {
        order: 2;
    }

    // INFO: Moche mais seul moyen pour que ça fonctionne quand on mets un px-X par défaut
    .md\:px-0 {
        padding-inline: 0 !important;
    }

    @import "./medium-up/components/about.scss";
    @import "./medium-up/components/modal.scss";
    @import "./medium-up/components/unboxing.scss";
    @import "./medium-up/components/testimonials.scss";
    @import "./medium-up/components/lateral.scss";
    @import "./medium-up/auth.scss";
    @import "./medium-up/home.scss";
    @import "./medium-up/bloc-slider.scss";
    @import "./medium-up/slide-hover.scss";
    @import "./medium-up/pictos.scss";
    @import "./medium-up/navbar.scss";
    @import "./medium-up/footer.scss";
    @import "./medium-up/universes.scss";
    @import "./medium-up/partners.scss";
    @import "./medium-up/basket.scss";
    @import "./medium-up/my-account.scss";
    @import "./medium-up/cards-presentation.scss";
    @import "./medium-up/purchases-confirmation.scss";
    @import "./medium-up/contact.scss";
    @import "./medium-up/faq.scss";
    @import "./medium-up/occasions.scss";
    @import "./medium-up/invitation.scss";
    @import "./medium-up/who-we-are.scss";
    @import "./medium-up/b2b/index.scss";
}

@include respond-to("large-up") {
    .container,
    .footer-content {
        max-width: 1280px;
    }

    @import "./large-up/navbar.scss";
    @import "./large-up/auth.scss";
    // @import "./large-up/components/lateral.scss";
}