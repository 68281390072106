// INFO: Les couleurs
$white: #FFFFFF;
$white-accent: #FFFDF7;
$black: #001A38;
$color: #03033B;
$error: #CF0C1F;
$input-error: #EB5757;

$default-color: #f9c622;
$default-color-hover: #FFCF7A;
$default-color-alpha: #f9c62296;

$secondary-color: #53D2FF;
$secondary-color-hover: #6ba7d2;
$light-blue: #59A3D8;

$box-shadow: rgba(0, 0, 0, .25);
$light-background: rgba(255, 255, 255, .1);