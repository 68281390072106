@if($font == 'Space Grotesk') {
    @font-face {
        font-family: 'Space Grotesk';
        font-display: swap;
        src: url(/fonts/SpaceGrotesk-VariableFont_wght.ttf) format('truetype');
    }
}

@if($font == 'INTERMARCHE-Regular') {
    @font-face {
        font-family: 'INTERMARCHE Regular';
        font-display: swap;
        src: url(/fonts/INTERMARCHE-Regular.otf) format('opentype');
    }

    @font-face {
        font-family: 'INTERMARCHE Bold';
        font-display: swap;
        src: url(/fonts/INTERMARCHE-Bold.otf) format('opentype');
    }
}