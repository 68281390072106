@if $theme == 'lebeaujeu' {
    .itm-logo {
        height: unset;
    }
}

.navbar {
    &.light {
        background: var(--white-accent);

        &:not(.hide) {
            height: 6rem;
        }
    }
    &.banners-container {
        &:not(.hide) {
            height: 4rem;
        }
        font-size: 1.6rem;
    }

    .navbar-burger {
        display: none;
    }

    .navbar-brand {
        height: 8rem;

        .md:flex {
            height: 100%;
        }
    }

    .navbar-item {
        padding-inline: 12px;

        &.has-dropdown {
            display: flex;

            .navbar-dropdown {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 100%;
            }
        }
    }

    .btn-icon {
        padding: 0;
        .wallet {
            span {
                padding-right: 2.3rem;
            }
        }
    }

    .beforeAddCredit {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .addCredit {
        margin-left: -10px;

        .btn {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

    }
    .navbar-dropdown-addCredit {
        right: 0;
        top: 65px !important;
        color: $black;

        &::before {
            right: 16px;
            left: unset;
        }
    }
}

.navbar-usertypes {
    height: 100%;

    div {
        a {
            color: var(--font-color-inverted);
        }
    }
}

#languages {
    .navbar-link {
        margin-top: 1rem;
        height: 4rem;
        border-left: .1rem solid rgba(3, 3, 59, .2);
        color: $black;

        img {
            margin-left: 2rem;
            width: 2.1rem;
            margin-right: 1rem;
        }

        &::before {
            display: none;
        }

        &::after {
            content: url('/images/icons/down.svg');
        }
    }
    .navbar-dropdown {
        background: var(--white-accent);
        top: 6rem;
        right: 0;
        width: auto;
        z-index: 2;
        padding: 2rem;

        img {
            width: 2.1rem;
            margin: 1rem;
        }
    }

    hr {
        border: .1rem solid rgba(3, 3, 59, .2);
        width: 100%;
        margin: .5rem 0;
    }


    a {
        color: $black;
    }
}

.navbar-basket {
    .icon {
        width: 5rem;

        span {
            top: .6rem;
            right: .9rem;
            width: 1.8rem;
            height: 1.8rem;
            font-size: 1rem;
        }
    }
}

.navbar-basket-container {
    width: 25.6rem;

    &::before {
        content: "";
        width: 2.2rem;
        height: 2.2rem;
        border-radius: $border-radius-small;
        background: $white;
        position: absolute;
        top: -.8rem;
        left: 11.7rem;
        transform: rotate(45deg);
    }
}

#menu-universes {
    a {
        font-size: 3rem;
    }

    img {
        width: 3rem;
    }
}

.menu-universes-all {
    min-height: 32rem;
}

.navbar-universes-container {
    .menu-universes-all {
        min-height: unset;
    }
}