ul.navbar-faq {
    height: auto;
    li {
        width: 67%;
        border: 1px solid #ffffff;
        border-radius: $border-radius-default;
        background: radial-gradient(ellipse at top right, #f9c62264 0, rgba(255, 207, 122, 0) 50%, rgba(0, 0, 30, 0.6) 100%);
        opacity: 0.5;

        svg {
            height: 22px;
            opacity: 0.75;
            .fill {
                fill: #FAFAFA;
            }
            .stroke {
                stroke: #FAFAFA;
            }
        }

        &:hover,
        &.active {
            border: 1px solid var(--default-color);
            background: radial-gradient(ellipse at top right, #f9c622 0, rgba(255, 207, 122, 0) 50%, rgba(0, 0, 30, 0.6) 100%);
            opacity: 1;
            @extend %transition;

            svg {
                .fill {
                    fill: var(--default-color);
                }
                .stroke {
                    stroke: var(--default-color);
                }
            }
        }

        &:hover {
            opacity: 0.7;
        }
    }
}

details {
    border-top: 1px solid rgba(250, 250, 250, 40%);

    summary {
        font-size: 2rem;
        margin-bottom: 2rem;
        padding-right: 20px;
        position: relative;
        cursor: pointer;

        &::marker {
            content: '';
            display: none;
            visibility: hidden;
            opacity: 0;
        }

        &::after {
            content: '';
            width: 18px;
            height: 100%;
            background-image: url('/images/icons/chevron-down.svg');
            position: absolute;
            right: 0;
            top: 0;
            background-size: contain;
            margin-left: .75em;
            transition: 0.2s;
            display: inline-block;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    &[open] > summary::after {
        transform: rotate(180deg);
    }

    .response {
        p {
            margin-top: 1.6rem;
        }

        ul {
            list-style: disc;
        }

        a {
            color: $light-blue;
            text-decoration: underline;
        }
    }
}