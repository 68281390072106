.who-we-are--description {
    order: 1;

    // font-size: 3rem;
    // em {
    //     font-size: 4rem;
    // }
}
.who-we-are--image {
    order: 2;
}

.who-we-are--header {
    iframe {
        width: 750px;
        height: 421px;
    }
}

.who-we-are--vision-mission {
    background-image: url('/images/who-we-are/main.webp');
    padding-top: 840px;
    background-size: cover;
}

.who-we-are--difference-card {
    .square {
        aspect-ratio: 1/1;
    }
}

#our-values {
    .item {
        width: calc(33% - 0.8rem);
    }
}

.who-we-are--partners {
    .item {
        width: 230px;
        height: 148px;
    }
}