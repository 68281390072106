.hero-animation {
    width: calc(50% - 140px);

    &.hero--left {
        left: -28px;
    }
    &.hero--right {
        right: -28px;
    }
}

.home--header {
    h1 {
        font-size: 7rem;
    }
    h2 {
        font-size: 4.2rem;

        span {
            display: inline-block;
        }
    }
}

.home--partners {
    h2 {
        font-size: 3rem;
    }

    .home--partners--content {
        width: 50%;
        left: 25%;
    }
}

.home--how-it-works-cta {
    display: flex;
    flex-direction: row;
}

.tabs-how-it-works--cards {
    h3 {
        font-size: 2.5rem;
    }
    .home--how-it-works--card {
        width: calc(25% - 2.4rem);
        background: transparent;

        .home--how-it-works--card--container {
            background: transparent;
            padding-bottom: 0;
        }
    }
}

#home--gift-cards-ideas {
    .tagline {
        max-width: 45%;
    }

    .home--gift-cards-ideas--card {
        display: inline-block;
        width: calc(22% - 1.6rem);
    }
}

.home--experience {
    p {
        opacity: 100%;
    }
}