.slider-container {
    .items.bought-card-items {
        padding-bottom: 1rem;

        .item {
            aspect-ratio: unset;
            @extend %gradientBackgroundAlternate;
            margin-top: 7rem;
            border-radius: $border-radius-default;
            width: calc(100% - 1.6rem);
            justify-content: space-between;
        }

        .rotated-card-image-container {
            margin-top: -7rem;
        }
    }

    a.btn-outline-primary {
        aspect-ratio: unset;
        border-width: 1px;
        border-style: solid;
    }
}