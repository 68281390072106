.overlay {
    position: fixed;
    padding-inline: 2.4rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    background: rgba(#03023B, 0.7);
    @if $theme == 'lebeaujeu' {
        background: rgba(#000000, 0.7);
    }

    &.show {
        visibility: visible;
        overflow-y: auto;
    }

    .close {
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;

        right: 1.6rem;
        top: 2.4rem;

        padding: 0;
        margin: 0;
        background: transparent;
        z-index: 1;

        img {
            padding: 0;
        }

        @include respond-to("medium-up") {
            right: 3.2rem;
            top: 3.2rem;
        }
    }
}

.overlay-content {
    position: relative;
}