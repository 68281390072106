.basket--main {
    h1 {
        font-size: 4rem;
        line-height: 4rem;
        font-weight: $font-weight-semibold;

        text-align: left;
    }
}

.basket--payment--container {
    background: linear-gradient(70.2deg, #00001E 0%, #001A38 100%);
    @if $theme == 'lebeaujeu' {
        background: rgba(255, 255, 255, 0.08);
    }

    .divider {
        display: block;
        position: relative;
        clear: both;
        width: 100%;
        height: 0.1rem;
        background: $white;
    }
}

.basket--payment--row {
    font-size: 1.8rem;

    .value {
        font-weight: $font-weight-bold;
    }

    .subtitle {
        display: block;
        font-size: 1.2rem;
    }

    &.wallet {
        .value {
            &::before {
                content: '+ ';
                color: #9DCE2B;
            }
        }
    }
}

.basket--items {
    a {
        text-decoration: underline;
    }

    .slider {
        display: flex;
        overflow-x: auto;
        gap: 0.8rem;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        width: 100%;
    }

    .basket--card {
        scroll-snap-align: start;
        flex-shrink: 0;
        width: 90%;
    }
}

.basket--payment-method {
    @extend %gradientBackground;
    border-radius: $border-radius-default;
    min-height: 74px;
}

.basket--offer,
.basket--card {
    @extend %gradientBackground;
    position: relative;
    border-radius: $border-radius-small;
    min-height: 132px;
}

.basket--offer--donation {
    background: linear-gradient(250.2deg, rgba(0, 0, 30, 0.5) 0%, rgba(0, 26, 56, 0.5) 100%);
    backdrop-filter: blur(5rem);
    border-radius: $border-radius-default;
    font-size: 1.2rem;

    &.form-check.rounded {
        label {
            margin: 0;
            width: 100%;

            &::before,
            &::after {
                left: initial;
                right: 1.6rem;
            }
        }

        input:checked + label::after {
            left: initial;
            right: 1.8rem;
            top: initial;
            margin-top: 0.2rem;
        }
    }
}

.basket--offer--donation--logo {
    width: 8rem;
}

.basket--offer--logo {
    margin-block: 2rem;
    width: 10rem;
}

.basket--card--logo {
    margin-block: 2rem;
    width: 14rem;

    .btn-play {
        width: 3rem;
        height: 3rem;
        background-color: rgba(3, 3, 59, 0.5);
        background-image: url('/images/icons/play-large.svg');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: -1rem;
        bottom: -0.8rem;
        border: 0.5rem solid rgba(3, 3, 59, 0.5);
        border-radius: 3rem;

        &:hover {
            opacity: 0.7;
        }
    }
}

.basket--offer--details,
.basket--card--details {
    width: 100%;
}

.basket--offer--name,
.basket--card--name {
    font-size: 1.8rem;
    font-weight: $font-weight-bold;
}

.basket--offer--summary {
    font-size: 1.2rem;
    font-weight: $font-weight-semibold;
    opacity: 0.7;
}
.basket--card--summary {
    font-size: 1.2rem;
    opacity: 0.7;
}

.basket--offer--price,
.basket--card--price {
    font-size: 2rem;
    font-weight: $font-weight-semibold;
}

.basket--offer--price {
    small {
        font-size: 1.4rem;
    }
}

.basket--offer--quantity {
    position: absolute;
    right: 1.6rem;
    bottom: 1.6rem;
    display: flex;
    flex-direction: row;

    button {
        position: absolute;
        width: 3rem;
        height: 3rem;
        padding: 0;
        border-radius: 100%;
        background: $white;
        color: $black;
        border: none;

        svg {
            width: 1.2rem;
            height: 1.2rem;
        }

        @if $theme != 'lebeaujeu' {
            &:first-of-type {
                left: 0;
            }
            &:last-of-type {
                right: 0;
            }
        } @else {
            right: 0;
            bottom: 0;
        }
    }

    input {
        width: 8rem;
        color: $white;
        @extend %gradientBackground;
        text-align: center;
        margin-inline: 1.5rem;
        height: 3rem;

        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.basket--card--remove {
    button {
        margin: 0;
        padding: 0;
        width: 2.4rem;
        height: 2.4rem;
        background: transparent;
        position: absolute;
        top: 2rem;
        right: 2rem;

        img {
            padding: 0;
            margin: 0;
            opacity: 0.7;
        }
    }
}

.basket--card--edit {
    .btn-link {
        font-size: 1.2rem;
        color: var(--default-color);
    }
}

#PaylineWidget {
    .pl-pmContainer {
        border-radius: $border-radius-small !important;
        border: none !important;
        background: linear-gradient(70.2deg, rgba(255, 255, 255, 0.1) 0%, rgba(247, 247, 247, 0.1) 100%) !important;
    }

    .pl-card-logos-container {
        background: transparent !important;
        border: none !important;
    }

    .pl-label-input {
        font-size: 1.4rem !important;
        line-height: 1.5rem !important;
        font-weight: $font-weight-semibold !important;
        margin-bottom: .8rem !important;
        color: $white !important;
    }

    .pl-pay-btn {
        font-weight: $font-weight-semibold;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: $border-radius-small;
        border: 1px solid transparent;
        color: $white;
        text-decoration: none;
        cursor: pointer;
        color: $black;
        border-color: var(--default-color);
        background: linear-gradient(45deg, var(--default-color) 0%, var(--default-color) 100%);

        &:hover {
            background: linear-gradient(45deg, var(--default-color) 0%, var(--default-color-hover) 100%);
            border-color: transparent;
        }
    }
}

.PaylineWidget .pl-icon-close::before,
.PaylineWidget .pl-icon-credit-card::before,
.PaylineWidget .pl-icon-date::before,
.PaylineWidget .pl-icon-cvv::before {
    color: $black;
}