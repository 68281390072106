.slider-container {
    width: 100%;

    .items {
        display: flex;
        overflow-x: auto;
        gap: 0.8rem;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
    }

    .item,
    label,
    a {
        scroll-snap-align: start;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
    }

    a.slider {
        width: 74vw;
        aspect-ratio: 280/222;
    }
}


.horizontal-scroll {
    article {
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;

        article {
            scroll-snap-align: start;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: .6rem;
        }
    }

    ul.navigation {
        display: flex;
    }
}