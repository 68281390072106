
.navbar {
    &.light {
        display: none;
    }
}

#navbar {
    .navbar-b2b-target-items {
        margin-left: 2.6rem;
    }
}