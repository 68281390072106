.b2b--home--targets--cards.slider.item {
    display: inline-block;
    width: calc(25% - 1.6rem);

    span {
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    &:hover {
        span {
            visibility: visible;
            opacity: 1;
            height: auto;
        }
    }
}


.b2b--home--customers {
    .item {
        width: 191px;
    }
}