.slide-hover {
    @if $theme == 'lebeaujeu' {
        position: relative;
    }

    &.with-background {
        // INFO: Version avec masque dans l'image
        background-image: var(--bg-image);
        // INFO: Version ou le masque est mis via css (pas utilisable avec le fond actuel)
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        height: 74vh;

        .logo {
            aspect-ratio: 1/1;
            width: 12rem;
        }
    }

    &.with-gradient {
        border-radius: $border-radius-default;
        background: #00001E66;
        // INFO: Test card partenaires sur la liste des univers.
        // Je trouve la couleur d'origine trop foncée, on ne voit pas assez la différence
        // background: rgba(96, 96, 104, 0.2);
        aspect-ratio: 1/1;
    }

    &.disabled {
        opacity: 0.4;
        cursor: default;
    }

    cursor: pointer;

    // .logo {
    //     aspect-ratio: 1/1;
    //     padding: 4rem;
    //     text-align: center;
    // }

    .btn {
        height: 5rem;
        color: $white;
    //     width: 100%;
        margin-top: 2rem;
    //     // background: var(--default-color);
    //     border: 1px solid var(--default-color);
    //     background: transparent;
    //     color: $white;
    //     padding-block: 1rem;
    //     display: block;

        &:hover {
            color: $white;
        }
    }

    p {
        font-size: 1.4rem;
    }

    svg {
        margin-left: .5rem;
    }

    .details {
        padding: 0 2.6rem;
    }

    .assets li {
        display: inline;
        padding-right: 0.8rem;
    }

    a {
        width: 100%;
        height: 100%;
        display: flex;
    }
}