.break-each-word {
    word-spacing: 100vw;
}

.light {
    font-weight: $font-weight-regular;
}
.bold {
    font-weight: $font-weight-bold;
}


.f-18 {
    font-size: 1.8rem;
}
.f-22 {
    font-size: 2.2rem;
}
.f-24 {
    font-size: 2.4rem;
}

h1,
h2,
h3,
h4 {
    em {
        font-style: normal;
        color: var(--color);
    }
}

p {
    em {
        color: var(--color);
        font-style: normal;
    }
}

.bullets {
    ul {
        list-style: disc;
    }
}

blockquote {
    font-style: italic;
    padding: 1.6rem 0 0 1.6rem;
}