.debug {
    position: fixed;
    bottom: 0;
    height: 0;
    width: 100%;
    border-top: .8rem solid var(--default-color);
    background: #001A38;

    &:hover {
        height: 75vh;
        overflow: auto;
    }
}