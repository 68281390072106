a {
    &.link-light {
        text-decoration: underline;
        opacity: 0.5;
    }

    &.link-small {
        font-size: 1.4rem;
    }

    &.link-back {
        position: absolute;
        z-index: 1;

        &::before {
            position: absolute;
            left: 0;
            content: "";
            background-image: url('/images/icons/chevron-left.white.svg');
            background-size: cover;
            width: 2.4rem;
            height: 2.4rem;
        }
    }

    &.glow-underline {
        position: relative;

        &.active {
            &::after {
                content: '';
                width: 100%;
                position: absolute;
                bottom: -1rem;
                left: 0;
                height: .3rem;
                background: var(--color);
                box-shadow: 0px 4px 9px 0px var(--color);

            }
        }
    }

    &.underline {
        text-decoration: underline;
    }
}