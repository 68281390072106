.giftCardIdea {
    .partner--hero {
        background-position: center center;
        height: 90vh;
    }

    h2 {
        font-size: 2.5rem;
    }

    em {
        font-style: normal;
        color: var(--color);
        display: inline;
    }

    section {
        strong {
            font-weight: $font-weight-bold;
            line-height: 150%;
        }
    }

    article {
        img {
            width: 24px;
        }
        h3 {
            font-size: 2rem;
        }
    }

    .assets {
        div {
            border-radius: $border-radius-default;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            &.a1 {
                aspect-ratio: 701/328;
            }

            &.b1 {
                aspect-ratio: 701/449;
            }

            &.a2 {
                aspect-ratio: 701/434;
            }

            &.b2 {
                aspect-ratio: 701/313;
            }
        }
    }

    .sub-bloc {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid var(--default-color);

        h2 {
            font-size: 2rem;
            font-weight: $font-weight-regular;
        }
    }
}