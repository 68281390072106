main.auth {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 14rem);
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .rotated-card-image-container {
        width: 50%;
        margin-inline: auto;
        margin-top: -4rem;
    }

    h1 {
        font-size: 4rem;
    }

    .auth--form,
    .auth-container {
        position: relative;
        width: 90vw;
        max-width: 92.4rem;
        @extend %gradientBackgroundAlternate;
        @if $theme == 'lebeaujeu' {
            background: rgba(255, 255, 255, 0.08);
            backdrop-filter: blur(50px);
        }

        // background: #000624;
        border-radius: $border-radius-default;
        padding: 4rem 2rem;
    }
}

.confirm-offers-logo-container {
    .confirm-offers-logo {
        position: relative;
        width: 10rem;
        height: 10rem;
        margin-top: -9rem;
        padding: 1.2rem;

        @extend %gradientBackground;
    }

    .quantity {
        position: absolute;
        right: 1.2rem;
        bottom: 1.2rem;
    }
}


@include respond-to("medium-up") {
    .confirm-offers-logo-container {
        .confirm-offers-logo {
            width: 20rem;
            height: 14rem;
        }
    }
}

