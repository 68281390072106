.universes {
    header {
        padding: 0 2.6rem;
        .btn {
            width: 100%;
        }
    }

    .giftCard {
        div.flex {
            order: 1;
        }
        h2 {
            order: 2;
            margin-top: 4rem;
            font-size: 4.4rem;
            line-height: 4.4rem;
            letter-spacing: -.05rem;
        }
        div {
            order: 3;
        }
        p {
            margin-top: 2rem;
        }

        .btn {
            width: 100%;
        }
    }

    .btn-container {
        width: 100%;
    }
}

.universes--about-universe {
    width: auto;
    padding-bottom: 10rem;
    background-size: 36%;
    background-position-y: bottom -3.7rem;

    h3 {
        font-size: 2rem;
        font-weight: $font-weight-semibold;
        line-height: 2.2rem;
        letter-spacing: 0em;
    }

    .btn {
        width: 100%;
    }
}

.universes-partners {
    em {
        display: block;
    }

    .input-bloc {
        margin-top: 1.6rem;
    }
}