@include respond-to("medium-up") {
    .scroll-down {
        &:after {
            position: absolute;
            content: '';
            display: block;
            background-image: url('/images/icons/scroll-down.svg');
            width: 1.5rem;
            height: 4.3rem;
            opacity: 0.5;
            margin-top: 3rem;
            margin-left: calc(50% - 0.75rem);
            left: 0;
            bottom: 0;

            animation-duration: 1.5s;
            animation-name: scrollDown;
            animation-direction: alternate;
            animation-iteration-count: infinite;
        }
    }
}

// Inspiration: Plebicom
@keyframes scrollDown {
    from {
        opacity: 0.5;
        bottom: 0;
    }

    to {
        opacity: 1;
        bottom: 1rem;
    }
}