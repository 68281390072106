.links-container {
    width: 100%;

    ul {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        gap: 2.4rem;
        height: 4rem;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            display: inline;
            flex-shrink: 0;
        }
    }
}