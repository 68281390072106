.pictos {
    position: absolute;
    top: 0.4rem;
    right: 0.5rem;

    @if $theme == 'lebeaujeu' {
        top: 1.3rem;
        right: 1.4rem;
    }
}

.picto {
    height: 2.4rem;
    border-radius: 2.4rem;
    border: 1px solid transparent;
    font-size: 1.4rem;
    padding-inline: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;


    @each $name, $color, $border, $font in $picto-variations {
        &.picto-#{$name} {
            background: $color;
            border-color: #{$border};
            color: #{$font};
        }

        &.picto-outline-#{$name} {
            background: transparent;
            border-color: #{$border};
            color: #{$color};
        }
    }
}