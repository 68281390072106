.companies {
    .navbar-logo {
        svg {
            .fill {
                fill: $secondary-color;
            }
            .stroke {
                stroke: $secondary-color;
            }
        }
    }
}