.account {
    .bloc {
        margin-top: 1.6rem;
        padding: 1.6rem;
    }

    @if $theme == 'lebeaujeu' {
        h2,
        h4 {
            text-transform: uppercase;
        }
    }
}

.account--profile {
    .showOrHidePassword {
        cursor: pointer;
    }

    .btn {
        padding-inline: 1rem;
    }
}

#account-interests--content {
    .grid {
        grid-auto-rows: 1fr;
    }
}

.account--sponsorship {
    .picto {
        background: var(--default-color);
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 4rem;
        padding: .8rem;

        img {
            width: 2.4rem;
            height: 2.4rem;
        }
    }

    small {
        opacity: 0.75;
    }
}

#sponsorship--fund--content {
    .sponsorship--square {
        border: 0.1rem solid #ffffff96;
        border-radius: $border-radius-small;
        padding: 1.2rem;
    }

    span {
        margin-top: 1.2rem;
        font-size: 3rem;
        margin-top: .8rem;
    }
}

#sponsorship--how-to--content {
    .step {
        width: calc(100% - 6.4rem);
    }

    label {
        text-transform: uppercase;
    }

    .btn-outline-white {
        padding: 0;
    }

}

.account--bills {
    .bloc {
        color: rgba($white, .7);
    }

    .operation {
        @extend %gradientBackground;
        grid-template-columns: auto auto;

        &.first {
            strong {
                color: var(--default-color);
            }
        }
    }

    .activation-date {
        color: rgba($white, 0.3);
    }

    .amount {
        color: $white;
        white-space: nowrap;
    }

    a.operation--bill {
        color: $default-color;
        span {
            text-decoration: underline;
        }
    }
}

.account--cards-received,
.account--codes-cards-sent {
    h4 {
        small {
            margin-left: 0.8rem;
            font-size: 2rem;
            opacity: 0.7;
        }
    }

    h5 {
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
    }

    span {
        opacity: 0.7;
        font-size: 1.2rem;
    }

    .rotated-card-image-container {
        margin: 0 auto 2rem;
    }

    .card {
        position: relative;
        margin-top: -9rem;
        padding: 1.6rem;
        padding-top: 9rem;
        border-radius: $border-radius-small;
        @extend %gradientBackground;
        width: 100%;
    }

    .card-content {
        padding: 1.6rem;
        @extend %gradientBackground;
        border-radius: $border-radius-small;
        margin-bottom: 1.1rem;
        min-height: 11rem;
    }

    .btn {
        width: 100%;
    }
}

.my-codes--item {
    position: relative;

    .picto {
        display: inline-block;
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding-block: 0.4rem;
        margin-top: 1.6rem;
    }

    &.type-archived {
        img {
            opacity: 0.5;
        }
    }
}



.my-codes--item--logo {
    aspect-ratio: 1/1;

    width: 48%;
    margin-inline: auto;
    max-height: 60px;
}

.my-codes--date {
    font-size: 1.2rem;
    opacity: 0.7;
}

.my-codes--item--how-to {
    font-size: 1.4rem;

    a {
        font-weight: $font-weight-bold;
        text-decoration: underline;
    }
}

.my-codes--item--activate-again {
    opacity: 0.7;
    font-weight: $font-weight-regular;
    font-size: 1.2rem;
}

.locked {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 2.4rem;
}

.modal-unlock-offer {
    .purchase-offers {
        img {
            width: 7rem;
        }
    }
}

.price {
    margin-left: auto;
    white-space: nowrap;
}

#editCard,
#shareOffer {
    .btn {
        svg {
            width: 1.6rem;
            margin-right: 1rem;

            path {
                fill: $black;
                stroke: $black;
            }
        }
    }

    .btn-outline-white {
        svg {
            path {
                fill: $white;
                stroke: $white;
            }
        }
    }
}

.confirmedEditCard,
.confirmedShareCode {
    @extend %gradientBackgroundAlternate;
    border-radius: $border-radius-default;
    position: relative;
    margin-top: 10rem;
    padding: 5rem;
}

.confirmedShareCode {
    img {
        height: 14rem;
        transform: rotate(-3deg);
        position: absolute;
        top: -9rem;
    }
}

.confirmedEditCard {
    .rotated-card-image-container {
        width: auto;
        height: 14rem;
        transform: rotate(-3deg);
        position: absolute;
        top: -9rem;
    }
}