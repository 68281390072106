.lateral--button {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 64px;
    background: linear-gradient(70.2deg, rgba(0, 0, 30, 0.7) 0%, rgba(0, 26, 56, 0.7) 100%);
    border-top-right-radius: $border-radius-large;
    border-bottom-right-radius: $border-radius-large;
    cursor: pointer;
}
.lateral--slider {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 80%;
    transform: translateX(-100%);
    transition: transform 1.2s ease;
    pointer-events: none;
}
.lateral--container {
    width: 100%;
    // background: var(--color);
    background: #fff linear-gradient(156deg, var(--color) 50%, var(--light) 93%);
    border-top-right-radius: $border-radius-large;
    border-bottom-right-radius: $border-radius-large;
    position: relative;
    pointer-events: initial;

    .close {
        position: absolute;
        top: 6px;
        right: 6px;
        cursor: pointer;
        height: 24px;
        z-index: 2;
    }

    .img--container {
        border-top-right-radius: $border-radius-large;
        border-bottom-right-radius: $border-radius-large;

        background-image: var(--image);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        aspect-ratio: 1.2/1;
        width: 100%;
    }

    .text--container {
        // background: #fff linear-gradient(156deg, var(--color) -25.31%, var(--light) 93%);
        border-bottom-right-radius: $border-radius-large;
    }

    strong {
        font-size: 120%;
    }
}