main.auth {
    .rotated-card-image-container {
        width: 29rem;
        margin-top: -14rem;
    }

    .confirm-offers-logo-container {
        .rotated-card-image-container {
            width: 100%;
            margin-top: 0;
        }
    }
}