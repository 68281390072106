.rotated-card-image-container {
    position: relative;
    margin-bottom: 2rem;

    .bubble,
    .universes,
    .message,
    .gif,
    .video {
        position: absolute;
        z-index: 2;

        border-radius: 3rem;

        visibility: hidden;
        opacity: 0;
    }
    .universes,
    .message,
    .gif {
        width: 3rem;
        height: 3rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .bubble {
        border: 0.1rem solid $default-color;
        background: $white;
        font-size: 1rem;
        color: #000;
        padding: 0.6rem 1.2rem;
        border-radius: 2.4rem;
        margin-bottom: 1rem;
        min-width: 5rem;
        transition: 0.75s;
        transform: rotate(3deg) translateY(100%);

        &.left {
            left: -3rem;
            &::after {
                content: '';
                display: inline-block;
                background-repeat: no-repeat;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12.859 7.32"><path stroke="%23f9c622" fill="%23ffffff" stroke-width="1.561" d="M.778.069c.117 1.628 3.959 4.904 11.31 6.47.087.019-.575-.217-.925-.6C8.69 3.24 9.168 1.044 9.33.137"/></svg>');
                width: 1.3rem;
                height: 0.732rem;
                bottom: -0.7rem;
                right: 1.4rem;
                position: absolute;
            }
        }

        &.right {
            right: -3rem;
            transition-delay: 0.5s;

            &::after {
                content: '';
                display: inline-block;
                background-repeat: no-repeat;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12.859 7.32"><path stroke="%23f9c622" fill="%23ffffff" stroke-width="1.561" d="M.778.069c.117 1.628 3.959 4.904 11.31 6.47.087.019-.575-.217-.925-.6C8.69 3.24 9.168 1.044 9.33.137"/></svg>');
                transform: scaleX(-1);
                width: 1.3rem;
                height: 0.732rem;
                bottom: -0.7rem;
                left: 1.4rem;
                position: absolute;
            }
        }

        &.recipientName {
            top: 0;
        }

        &.occasion {
            bottom: 0;
        }

        &.visible {
            visibility: visible;
            opacity: 1;

            &.recipientName {
                top: 3rem;
            }

            &.occasion {
                bottom: 6.2rem;
            }
        }
    }

    .universes {
        transition: 1s;
        transform: rotate(3deg) translateY(100%);
        top: -10vh;

        img {
            width: 1.7rem;
        }

        &.firstUniverse {
            background: var(--color);
            left: 0;

            &::before {
                content: '';
                display: inline-block;
                width: 0.3rem;
                height: 0.3rem;
                border-radius: 0.3rem;
                background: var(--color);
                position: absolute;
                top: -0.4rem;
                left: -1.3rem;
            }
            &::after {
                content: '';
                display: inline-block;
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 0.7rem;
                background: var(--color);
                position: absolute;
                top: 1.6rem;
                left: -1.2rem;
            }
        }
        &.secondUniverse {
            background: var(--color);
            right: -1.6rem;
            transition: 1.25s;

            &::before {
                content: '';
                display: inline-block;
                width: 0.3rem;
                height: 0.3rem;
                border-radius: 0.3rem;
                background: var(--color);
                position: absolute;
                top: -0.4rem;
                left: -0.6rem;
            }
            &::after {
                content: '';
                display: inline-block;
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 0.7rem;
                background: var(--color);
                position: absolute;
                top: 1.6rem;
                right: -1.2rem;
            }
        }

        &.visible {
            visibility: visible;
            opacity: 1;

            &.firstUniverse {
                top: -3rem;
            }

            &.secondUniverse {
                top: 4.1rem;
            }
        }
    }

    .message {
        transition: 0.33s;
        transition-delay: 0.75s;
        transform: rotate(3deg) translateY(100%) translateX(100%);
        right: 1.5rem;
        top: -24rem;

        background: $white;
        border: 1px solid $default-color;

        img {
            width: 1.5rem;
        }

        &::before,
        &::after {
            content: '';
            display: inline-block;
            background: $default-color;
            position: absolute;
        }

        &::before {
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 0.3rem;
            top: -0.4rem;
            right: -0.6rem;
        }
        &::after {
            width: 0.7rem;
            height: 0.7rem;
            border-radius: 0.7rem;
            top: 0.2rem;
            right: -1.8rem;
        }

        &.visible {
            visibility: visible;
            opacity: 1;
            top: -3rem;
        }
    }

    .gif {
        transition: 0.66s;
        transform: rotate(3deg) translateY(100%) translateX(100%);
        left: -100vw;
        top: 13rem;

        border: 1px solid $default-color;

        border-radius: $border-radius-xsmall;

        img {
            width: 4rem;
            max-height: 5rem;
            border-radius: $border-radius-xsmall;
        }

        &::before,
        &::after {
            content: '';
            display: inline-block;
            background: $default-color;
            position: absolute;
        }

        &::before {
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 0.3rem;
            top: -0.6rem;
            left: -0.2rem;
        }
        &::after {
            width: 0.7rem;
            height: 0.7rem;
            border-radius: 0.7rem;
            bottom: -0.2rem;
            right: -1.4rem;
        }

        &.visible {
            visibility: visible;
            opacity: 1;
            left: -5rem;
        }
    }

    .video {
        transition: 0.33s;
        transition-delay: 0.75s;
        transform: rotate(3deg) translateY(100%) translateX(100%);
        right: 1.5rem;
        top: -24rem;
        border-radius: $border-radius-small;

        background: $white;
        border: 1px solid $default-color;

        video {
            width: 3rem;
        }

        &::before,
        &::after {
            content: '';
            display: inline-block;
            background: $default-color;
            position: absolute;
        }

        &::before {
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 0.3rem;
            top: 0.6rem;
            left: -1.2rem;
        }
        &::after {
            width: 0.7rem;
            height: 0.7rem;
            border-radius: 0.7rem;
            bottom: 0.4rem;
            right: -1.8rem;
        }

        &.visible {
            visibility: visible;
            opacity: 1;
            top: -3rem;
        }
    }
}

.cards-presentation-assets {
    margin: 1rem 1.8rem;
    text-align: center;
    span {
        margin-inline: 0.5rem;
        &::before {
            content: "";
            display: inline-block;
            background: url('/images/icons/check.svg');
            background-size: cover;
            background-position: center center;
            width: 1.7rem;
            height: 1.3rem;
            margin-right: 0.5rem;
        }
    }
}

.cards-presentation-stepper {
    position: absolute;
    top: -2rem;
    left: 50%;
    width: 29rem;
    margin: 0 auto;
    margin-left: -14.5rem;
    height: 4rem;
    border-radius: 8.8rem;
    @extend %gradientWidthBackdrop;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding-inline: 3rem;
}

.cards-presentation-stepper-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;

    &::before {
        content: '';
        display: inline-block;
        background: $color;
        border-radius: 1rem;
        height: 1rem;
        width: 1rem;
        padding: 0;
        margin: 0;
    }
    &::after {
        content: '';
        display: inline-block;
        background: $color;
        height: 0.1rem;
        width: calc(100% - 1rem);

    }

    &.active,
    &.complete {
        &::before,
        &::after {
            background: var(--default-color);
        }
    }

    &.complete {
        cursor: pointer;
    }
}

.cards-presentation-form {
    position: relative;
    width: 100%;
    margin-top: 3.5rem;
    @extend %gradientWidthBackdrop;

    h1 {
        font-size: 3rem;
        font-weight: $font-weight-bold;
    }

    p {
        text-align: center;
        font-size: 1.4rem;
        color: $white-accent;
    }

    label {
        font-weight: $font-weight-medium;
    }

    .price-choice {
        label {
            width: 100%;
            height: 5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: rgba($white-accent, 0.1);
            border-radius: $border-radius-small;
            text-align: center;
        }

        input {
            visibility: hidden;
            display: none;
        }

        input:checked + label {
            background: $white-accent;
            color: $color;
            font-weight: $font-weight-bold;
        }
    }

    .price-free-choice {
        .input-group,
        input,
        .input-group-text.btn {
            margin: 0;
            height: 100%;
        }
    }

    .btn-link {
        opacity: 0.75;
    }

    .btn-white {
        font-weight: $font-weight-bold;
    }
}

.cards-presentation-actions {
    min-width: 20rem;
    button {
        flex: 1 1 auto;

        svg {
            margin-left: 8px;
            height: 19px;
        }
    }
}

.btn.cards-presentation-btn-message-type {
    background: radial-gradient(circle at top right, #ffffff77 0, rgba(255, 207, 122, 0) 50%, rgba(3, 2, 59, 0.4) 100%);
    backdrop-filter: blur(50px);
    height: auto;
    gap: 0.6rem;
    font-weight: $font-weight-semibold;

    &.selected {
        background: white;
        color: $black;

        svg {
            path {
                fill: $black;
            }
        }
    }

    svg,
    img {
        height: 2.2rem;
        opacity: 0.75;
        margin: 0;
        padding: 0;
    }

    small {
        font-weight: $font-weight-medium;
    }
}

.cards-presentation-message-type {
    .btn-link {
        color: var(--default-color);
        opacity: 1;
    }
}

.cards-presentation-message-type-message-extra {
    .items {
        width: 100%;
    }

    .gifChoice {
        position: relative;
        background-image: var(--bg-image);
        background-position: center center;
        background-size: cover;
        // width: 80vw;
        // aspect-ratio: 191/149;
        width: calc(25% - 1.6rem);
        aspect-ratio: 4/3;
        border-radius: $border-radius-default;
        flex-shrink: 0;

        &:has(input:checked) {
            border: 2px solid var(--default-color);

            &::after {
                content: "";
                width: 2.2rem;
                height: 2.2rem;
                position: absolute;
                bottom: 1.5rem;
                right: 1.5rem;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url('/images/icons/selected.svg');
                background-repeat: no-repeat;
            }
        }
    }

    input[type=radio] {
        display: none;
    }

    img {
        border-radius: $border-radius-default;
    }
}

.cards-presentation-message-type-message-extra-image-drop {
    @extend %gradientBackground;
    border-radius: $border-radius-small;

    .information {
        img {
            width: 3.2rem;
        }
    }

    .yellow {
        color: $default-color;
        text-decoration: underline;
    }

    img.preview {
        width: 100%;
        max-width: 20rem;
        border-radius: $border-radius-small;
    }
}

.camera {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: #05103c;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .timer {
        position: absolute;
        font-size: 9.6rem;
        font-weight: $font-weight-bold;
        color: $white;
        margin: auto;
        top: 50%;
        margin-top: -4.8rem;

        img {
            height: 9.6rem;
        }

        .percentage {
            font-size: 1.8rem;
        }
    }

    button {
        position: absolute;
        margin: auto;
        padding-inline: 4.8rem;
        bottom: 3rem;

        &.btn-cancel {
            bottom: unset;
            top: 2rem;
            right: 2rem;

            color: $default-color;
            text-decoration: underline;
        }

        img {
            height: 2.4rem;
        }
    }
}

#captured {
    border: 1px solid $white;
    border-radius: $border-radius-small;
}

.cards-presentation-form {
    .cards-container {
        width: 100%;

        .items {
            gap: .8rem;
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;
        }

        label {
            width: 74vw;
            padding-block: 2rem;

            svg {
                width: 100%;
                .fillMe {
                    fill: var(--card-color);
                }
                .strokeMe {
                    stroke: var(--card-color);
                }
            }

            &:has(input:checked) {
                padding: 2rem;
                svg {
                    transform: scale(1.2) rotate(-3deg);
                }
            }
        }

        input {
            display: none;
            visibility: hidden;
        }
    }

    .button-type-container {
        svg {
            width: 1.6rem;
            margin-right: 0.3rem;
            path {
                fill: $black;
                stroke: $black;
            }
        }

        .btn-outline-white {
            svg {
                path {
                    fill: $white;
                    stroke: $white;
                }
            }
        }
    }
}

.card-preview {
    position: relative;
    width: 333px;
    height: 650px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .iphone {
        width: 333px;
        height: 650px;
        background-image: url('/images/iphone.png');
        z-index: 2;
        position: absolute;
        pointer-events: none;
    }
    .preview {
        z-index: 1;
        position: absolute;
        top: 27px;
        left: 28px;
        width: 276px;
        height: 592px;
        background-image: url('/images/iphone-background.webp');
    }
    button {
        z-index: 3;
        background: transparent;

        &:hover {
            opacity: 0.75;
        }

        svg {
            width: 52px;
        }
    }
}
.card-preview-form {
    width: 100%;
}